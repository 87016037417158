let data;
export default data = {
  classes: "wb-tables",
  rounds: [
    {
      drawNumber: "321",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=321'>321</a>",
      drawDate: "2024-10-23",
      drawDateFull: "October 23, 2024",
      drawName: "Trade occupations (Version 1)",
      drawSize: "1,800",
      drawCRS: "433",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=321'>Invitations to apply for permanent residence under the Express Entry system #321</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=321'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "October 23, 2024 at 14:48:28 UTC",
      drawCutOff: "March 12, 2024 at 14:30:36 UTC",
      drawDistributionAsOn: "October 22, 2024",
      dd1: "124",
      dd2: "13,430",
      dd3: "63,285",
      dd4: "11,925",
      dd5: "12,240",
      dd6: "14,914",
      dd7: "12,900",
      dd8: "11,306",
      dd9: "55,503",
      dd10: "10,931",
      dd11: "12,047",
      dd12: "10,583",
      dd13: "11,071",
      dd14: "10,871",
      dd15: "51,048",
      dd16: "22,928",
      dd17: "5,462",
      dd18: "211,780",
    },

    {
      drawNumber: "320",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=320'>320</a>",
      drawDate: "2024-10-22",
      drawDateFull: "October 22, 2024",
      drawName: "Canadian Experience Class",
      drawSize: "400",
      drawCRS: "539",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=320'>Invitations to apply for permanent residence under the Express Entry system #320</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=320'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "October 22, 2024 at 14:07:18 UTC",
      drawCutOff: "October 19, 2024 at 21:53:18 UTC",
      drawDistributionAsOn: "October 22, 2024",
      dd1: "124",
      dd2: "13,430",
      dd3: "63,285",
      dd4: "11,925",
      dd5: "12,240",
      dd6: "14,914",
      dd7: "12,900",
      dd8: "11,306",
      dd9: "55,503",
      dd10: "10,931",
      dd11: "12,047",
      dd12: "10,583",
      dd13: "11,071",
      dd14: "10,871",
      dd15: "51,048",
      dd16: "22,928",
      dd17: "5,462",
      dd18: "211,780",
    },

    {
      drawNumber: "319",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=319'>319</a>",
      drawDate: "2024-10-21",
      drawDateFull: "October 21, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "648",
      drawCRS: "791",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=319'>Invitations to apply for permanent residence under the Express Entry system #319</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=319'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "October 21, 2024 at 15:12:14 UTC",
      drawCutOff: "August 13, 2024 at 20:04:52 UTC",
      drawDistributionAsOn: "October 21, 2024",
      dd1: "705",
      dd2: "13,209",
      dd3: "63,152",
      dd4: "11,903",
      dd5: "12,232",
      dd6: "14,872",
      dd7: "12,856",
      dd8: "11,289",
      dd9: "55,371",
      dd10: "10,903",
      dd11: "12,019",
      dd12: "10,558",
      dd13: "11,046",
      dd14: "10,845",
      dd15: "50,989",
      dd16: "22,920",
      dd17: "5,441",
      dd18: "211,787",
    },
    {
      drawNumber: "318",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=318'>318</a>",
      drawDate: "2024-10-10",
      drawDateFull: "October 10, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "1,000",
      drawCRS: "444",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=318'>Invitations to apply for permanent residence under the Express Entry system #318</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=318'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "October 10, 2024 at 15:45:35 UTC",
      drawCutOff: "September 27, 2024 at 18:56:32 UTC",
      drawDistributionAsOn: "October 9, 2024",
      dd1: "216",
      dd2: "12,312",
      dd3: "63,026",
      dd4: "11,840",
      dd5: "12,282",
      dd6: "14,794",
      dd7: "12,829",
      dd8: "11,281",
      dd9: "54,542",
      dd10: "10,903",
      dd11: "11,876",
      dd12: "10,361",
      dd13: "10,733",
      dd14: "10,669",
      dd15: "50,737",
      dd16: "23,082",
      dd17: "5,402",
      dd18: "209,317",
    },
    {
      drawNumber: "317",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=317'>317</a>",
      drawDate: "2024-10-09",
      drawDateFull: "October 9, 2024",
      drawName: "Canadian Experience Class",
      drawSize: "500",
      drawCRS: "539",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=317'>Invitations to apply for permanent residence under the Express Entry system #317</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=317'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "October 09, 2024 at 14:22:23 UTC",
      drawCutOff: "February 20, 2024 at 02:57:40 UTC",
      drawDistributionAsOn: "October 9, 2024",
      dd1: "216",
      dd2: "12,312",
      dd3: "63,026",
      dd4: "11,840",
      dd5: "12,282",
      dd6: "14,794",
      dd7: "12,829",
      dd8: "11,281",
      dd9: "54,542",
      dd10: "10,903",
      dd11: "11,876",
      dd12: "10,361",
      dd13: "10,733",
      dd14: "10,669",
      dd15: "50,737",
      dd16: "23,082",
      dd17: "5,402",
      dd18: "209,317",
    },
    {
      drawNumber: "316",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=316'>316</a>",
      drawDate: "2024-10-07",
      drawDateFull: "October 7, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "1,613",
      drawCRS: "743",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=316'>Invitations to apply for permanent residence under the Express Entry system #316</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=316'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "October 7, 2024 at 15:02:50 UTC",
      drawCutOff: "March 22, 2024 at 18:36:54 UTC",
      drawDistributionAsOn: "October 7, 2024",
      dd1: "1,656",
      dd2: "11,800",
      dd3: "62,853",
      dd4: "11,815",
      dd5: "12,236",
      dd6: "14,729",
      dd7: "12,818",
      dd8: "11,255",
      dd9: "54,313",
      dd10: "10,845",
      dd11: "11,804",
      dd12: "10,340",
      dd13: "10,707",
      dd14: "10,617",
      dd15: "50,741",
      dd16: "23,155",
      dd17: "5,374",
      dd18: "209,892",
    },
    {
      drawNumber: "315",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=315'>315</a>",
      drawDate: "2024-09-19",
      drawDateFull: "September 19, 2024",
      drawName: "Canadian Experience Class",
      drawSize: "4,000",
      drawCRS: "509",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=315'>Invitations to apply for permanent residence under the Express Entry system #315</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=315'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "September 19, 2024 at 13:12:22 UTC",
      drawCutOff: "November 06, 2023 at 20:17:35 UTC",
      drawDistributionAsOn: "September 19, 2024",
      dd1: "764",
      dd2: "11,752",
      dd3: "61,769",
      dd4: "11,352",
      dd5: "12,110",
      dd6: "14,582",
      dd7: "12,690",
      dd8: "11,035",
      dd9: "53,270",
      dd10: "10,655",
      dd11: "11,528",
      dd12: "10,102",
      dd13: "10,598",
      dd14: "10,387",
      dd15: "51,137",
      dd16: "23,613",
      dd17: "5,457",
      dd18: "207,762",
    },
    {
      drawNumber: "314",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=314'>314</a>",
      drawDate: "2024-09-13",
      drawDateFull: "September 13, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "1,000",
      drawCRS: "446",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=314'>Invitations to apply for permanent residence under the Express Entry system #314</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=314'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "September 13, 2024 at 13:38:58 UTC",
      drawCutOff: "August 28, 2024 at 17:07:19 UTC",
      drawDistributionAsOn: "September 9, 2024",
      dd1: "937",
      dd2: "9,987",
      dd3: "61,797",
      dd4: "11,274",
      dd5: "12,131",
      dd6: "14,665",
      dd7: "12,717",
      dd8: "11,010",
      dd9: "52,525",
      dd10: "10,496",
      dd11: "11,357",
      dd12: "9,948",
      dd13: "10,474",
      dd14: "10,250",
      dd15: "51,188",
      dd16: "23,708",
      dd17: "5,427",
      dd18: "205,569",
    },

    {
      drawNumber: "313",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=313'>313</a>",
      drawDate: "2024-09-09",
      drawDateFull: "September 9, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "911",
      drawCRS: "732",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=313'>Invitations to apply for permanent residence under the Express Entry system #313</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=313'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "September 09, 2024 at 15:42:40 UTC",
      drawCutOff: "March 13, 2024 at 19:56:16 UTC",
      drawDistributionAsOn: "September 9, 2024",
      dd1: "937",
      dd2: "9,987",
      dd3: "61,797",
      dd4: "11,274",
      dd5: "12,131",
      dd6: "14,665",
      dd7: "12,717",
      dd8: "11,010",
      dd9: "52,525",
      dd10: "10,496",
      dd11: "11,357",
      dd12: "9,948",
      dd13: "10,474",
      dd14: "10,250",
      dd15: "51,188",
      dd16: "23,708",
      dd17: "5,427",
      dd18: "205,569",
    },

    {
      drawNumber: "312",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=312'>312</a>",
      drawDate: "2024-08-27",
      drawDateFull: "August 27, 2024",
      drawName: "Canadian Experience Class",
      drawSize: "3,300",
      drawCRS: "507",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=312'>Invitations to apply for permanent residence under the Express Entry system #312</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=312'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "August 27, 2024 at 17:44:02 UTC",
      drawCutOff: "April 17, 2024 at 20:11:20 UTC",
      drawDistributionAsOn: "August 27, 2024",
      dd1: "127",
      dd2: "9,764",
      dd3: "61,133",
      dd4: "10,918",
      dd5: "12,038",
      dd6: "14,722",
      dd7: "12,676",
      dd8: "10,779",
      dd9: "51,863",
      dd10: "10,259",
      dd11: "11,142",
      dd12: "9,814",
      dd13: "10,493",
      dd14: "10,155",
      dd15: "52,363",
      dd16: "24,396",
      dd17: "5,491",
      dd18: "205,137",
    },

    {
      drawNumber: "311",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=311'>311</a>",
      drawDate: "2024-08-26",
      drawDateFull: "August 26, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "1,121",
      drawCRS: "694",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=311'>Invitations to apply for permanent residence under the Express Entry system #311</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=311'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "August 26, 2024 at 14:46:49 UTC",
      drawCutOff: "August 03, 2024 at 18:54:27 UTC",
      drawDistributionAsOn: "August 26, 2024",
      dd1: "1,154",
      dd2: "9,447",
      dd3: "60,991",
      dd4: "10,899",
      dd5: "12,030",
      dd6: "14,681",
      dd7: "12,639",
      dd8: "10,742",
      dd9: "51,705",
      dd10: "10,227",
      dd11: "11,120",
      dd12: "9,799",
      dd13: "10,450",
      dd14: "10,109",
      dd15: "52,302",
      dd16: "24,359",
      dd17: "5,490",
      dd18: "205,448",
    },
    {
      drawNumber: "310",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=310'>310</a>",
      drawDate: "2024-08-15",
      drawDateFull: "August 15, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "2,000",
      drawCRS: "394",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=310'>Invitations to apply for permanent residence under the Express Entry system #310</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=310'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "August 15, 2024 at 2024-08-15 15:11:54 UTC",
      drawCutOff: "July 07, 2024 at 09:46:10 UTC",
      drawDistributionAsOn: "August 14, 2024",
      dd1: "125",
      dd2: "11,055",
      dd3: "61,210",
      dd4: "10,883",
      dd5: "11,990",
      dd6: "14,873",
      dd7: "12,747",
      dd8: "10,717",
      dd9: "52,261",
      dd10: "10,303",
      dd11: "11,229",
      dd12: "9,868",
      dd13: "10,619",
      dd14: "10,242",
      dd15: "53,137",
      dd16: "24,690",
      dd17: "5,482",
      dd18: "207,960",
    },
    {
      drawNumber: "309",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=309'>309</a>",
      drawDate: "2024-08-14",
      drawDateFull: "August 14, 2024",
      drawName: "Canadian Experience Class",
      drawSize: "3,200",
      drawCRS: "509",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=309'>Invitations to apply for permanent residence under the Express Entry system #309</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=309'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "August 14, 2024 at 14:04:45 UTC",
      drawCutOff: "March 01, 2024 at 19:23:21 UTC",
      drawDistributionAsOn: "August 14, 2024",
      dd1: "125",
      dd2: "11,055",
      dd3: "61,210",
      dd4: "10,883",
      dd5: "11,990",
      dd6: "14,873",
      dd7: "12,747",
      dd8: "10,717",
      dd9: "52,261",
      dd10: "10,303",
      dd11: "11,229",
      dd12: "9,868",
      dd13: "10,619",
      dd14: "10,242",
      dd15: "53,137",
      dd16: "24,690",
      dd17: "5,482",
      dd18: "207,960",
    },
    {
      drawNumber: "308",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=308'>308</a>",
      drawDate: "2024-08-13",
      drawDateFull: "August 13, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "763",
      drawCRS: "690",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=308'>Invitations to apply for permanent residence under the Express Entry system #308</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=308'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "August 13, 2024 at 14:57:21 UTC",
      drawCutOff: "April 26, 2024 at 10:59:30 UTC",
      drawDistributionAsOn: "August 13, 2024",
      dd1: "713",
      dd2: "10,502",
      dd3: "60,902",
      dd4: "10,780",
      dd5: "11,959",
      dd6: "14,813",
      dd7: "12,705",
      dd8: "10,645",
      dd9: "51,992",
      dd10: "10,256",
      dd11: "11,169",
      dd12: "9,845",
      dd13: "10,539",
      dd14: "10,183",
      dd15: "53,052",
      dd16: "24,695",
      dd17: "5,463",
      dd18: "207,319",
    },
    {
      drawNumber: "307",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=307'>307</a>",
      drawDate: "2024-07-31",
      drawDateFull: "July 31, 2024",
      drawName: "Canadian Experience Class",
      drawSize: "5,000",
      drawCRS: "510",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=307'>Invitations to apply for permanent residence under the Express Entry system #307</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=307'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "July 31, 2024 at 14:48:13 UTC",
      drawCutOff: "June 30, 2024 at 20:39:14 UTC",
      drawDistributionAsOn: "July 31, 2024",
      dd1: "105",
      dd2: "12,455",
      dd3: "60,511",
      dd4: "10,172",
      dd5: "12,015",
      dd6: "14,994",
      dd7: "12,656",
      dd8: "10,674",
      dd9: "51,969",
      dd10: "10,137",
      dd11: "11,183",
      dd12: "9,798",
      dd13: "10,506",
      dd14: "10,345",
      dd15: "54,320",
      dd16: "25,553",
      dd17: "5,608",
      dd18: "210,521",
    },
    {
      drawNumber: "306",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=306'>306</a>",
      drawDate: "2024-07-30",
      drawDateFull: "July 30, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "964",
      drawCRS: "686",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=306'>Invitations to apply for permanent residence under the Express Entry system #306</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=306'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "July 30, 2024 at 15:25:21 UTC",
      drawCutOff: "May 27, 2024 at 07:07:21 UTC",
      drawDistributionAsOn: "July 30, 2024",
      dd1: "1,000",
      dd2: "12,250",
      dd3: "60,312",
      dd4: "10,114",
      dd5: "11,971",
      dd6: "15,004",
      dd7: "12,607",
      dd8: "10,616",
      dd9: "51,793",
      dd10: "10,114",
      dd11: "11,133",
      dd12: "9,768",
      dd13: "10,445",
      dd14: "10,333",
      dd15: "54,274",
      dd16: "25,481",
      dd17: "5,594",
      dd18: "210,704",
    },

    {
      drawNumber: "305",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=305'>305</a>",
      drawDate: "2024-07-18",
      drawDateFull: "July 18, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "1,800",
      drawCRS: "400",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=305'>Invitations to apply for permanent residence under the Express Entry system #305</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=305'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "July 18, 2024 at 14:58:28 UTC",
      drawCutOff: "March 08, 2024 at 19:51:40 UTC",
      drawDistributionAsOn: "July 17, 2024",
      dd1: "184",
      dd2: "16,968",
      dd3: "60,115",
      dd4: "9,982",
      dd5: "11,852",
      dd6: "15,120",
      dd7: "12,616",
      dd8: "10,545",
      dd9: "52,635",
      dd10: "10,067",
      dd11: "11,020",
      dd12: "9,701",
      dd13: "10,888",
      dd14: "10,959",
      dd15: "54,597",
      dd16: "25,669",
      dd17: "5,567",
      dd18: "215,735",
    },
    {
      drawNumber: "304",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=304'>304</a>",
      drawDate: "2024-07-17",
      drawDateFull: "July 17, 2024",
      drawName: "Canadian Experience Class",
      drawSize: "6,300",
      drawCRS: "515",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=304'>Invitations to apply for permanent residence under the Express Entry system #304</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=304'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "July 17, 2024 at 16:17:31 UTC",
      drawCutOff: "February 03, 2024 at 13:01:06 UTC",
      drawDistributionAsOn: "July 17, 2024",
      dd1: "184",
      dd2: "16,968",
      dd3: "60,115",
      dd4: "9,982",
      dd5: "11,852",
      dd6: "15,120",
      dd7: "12,616",
      dd8: "10,545",
      dd9: "52,635",
      dd10: "10,067",
      dd11: "11,020",
      dd12: "9,701",
      dd13: "10,888",
      dd14: "10,959",
      dd15: "54,597",
      dd16: "25,669",
      dd17: "5,567",
      dd18: "215,735",
    },

    {
      drawNumber: "303",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=303'>303</a>",
      drawDate: "2024-07-16",
      drawDateFull: "July 16, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "1,391",
      drawCRS: "670",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=303'>Invitations to apply for permanent residence under the Express Entry system #303</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=303'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "July 16, 2024 at 15:39:40 UTC",
      drawCutOff: "May 04, 2024 at 13:27:31 UTC",
      drawDistributionAsOn: "July 16, 2024",
      dd1: "1,407",
      dd2: "16,800",
      dd3: "60,006",
      dd4: "9,947",
      dd5: "11,809",
      dd6: "15,113",
      dd7: "12,620",
      dd8: "10,517",
      dd9: "52,500",
      dd10: "10,047",
      dd11: "10,978",
      dd12: "9,661",
      dd13: "10,880",
      dd14: "10,934",
      dd15: "54,576",
      dd16: "25,621",
      dd17: "5,547",
      dd18: "216,457",
    },

    {
      drawNumber: "302",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=302'>302</a>",
      drawDate: "2024-07-08",
      drawDateFull: "July 8, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "3,200",
      drawCRS: "420",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=302'>Invitations to apply for permanent residence under the Express Entry system #302</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=302'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "July 08, 2024 at 14:01:33 UTC",
      drawCutOff: "May 30, 2024 at 03:37:43 UTC",
      drawDistributionAsOn: "July 2, 2024",
      dd1: "940",
      dd2: "16,521",
      dd3: "64,761",
      dd4: "10,295",
      dd5: "12,447",
      dd6: "16,371",
      dd7: "13,883",
      dd8: "11,765",
      dd9: "54,148",
      dd10: "11,031",
      dd11: "11,342",
      dd12: "9,947",
      dd13: "10,876",
      dd14: "10,952",
      dd15: "54,902",
      dd16: "25,752",
      dd17: "5,523",
      dd18: "222,547",
    },
    {
      drawNumber: "301",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=301'>301</a>",
      drawDate: "2024-07-05",
      drawDateFull: "July 5, 2024",
      drawName: "Healthcare occupations (Version 1)",
      drawSize: "3,750",
      drawCRS: "445",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=301'>Invitations to apply for permanent residence under the Express Entry system #301</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=301'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "July 05, 2024 at 16:03:12 UTC",
      drawCutOff: "May 07, 2024 at 21:27:04 UTC",
      drawDistributionAsOn: "July 2, 2024",
      dd1: "940",
      dd2: "16,521",
      dd3: "64,761",
      dd4: "10,295",
      dd5: "12,447",
      dd6: "16,371",
      dd7: "13,883",
      dd8: "11,765",
      dd9: "54,148",
      dd10: "11,031",
      dd11: "11,342",
      dd12: "9,947",
      dd13: "10,876",
      dd14: "10,952",
      dd15: "54,902",
      dd16: "25,752",
      dd17: "5,523",
      dd18: "222,547",
    },
    {
      drawNumber: "300",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=300'>300</a>",
      drawDate: "2024-07-04",
      drawDateFull: "July 4, 2024",
      drawName: "Trade occupations (Version 1)",
      drawSize: "1,800",
      drawCRS: "436",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=300'>Invitations to apply for permanent residence under the Express Entry system #300</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=300'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "July 4, 2024 at 13:30:48 UTC",
      drawCutOff: "July 16, 2023 at 15:44:59 UTC",
      drawDistributionAsOn: "July 2, 2024",
      dd1: "940",
      dd2: "16,521",
      dd3: "64,761",
      dd4: "10,295",
      dd5: "12,447",
      dd6: "16,371",
      dd7: "13,883",
      dd8: "11,765",
      dd9: "54,148",
      dd10: "11,031",
      dd11: "11,342",
      dd12: "9,947",
      dd13: "10,876",
      dd14: "10,952",
      dd15: "54,902",
      dd16: "25,752",
      dd17: "5,523",
      dd18: "222,547",
    },
    {
      drawNumber: "299",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=299'>299</a>",
      drawDate: "2024-07-02",
      drawDateFull: "July 2, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "920",
      drawCRS: "739",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=299'>Invitations to apply for permanent residence under the Express Entry system #299</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=299'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "July 2, 2024 at 15:12:05 UTC",
      drawCutOff: "February 02, 2024 at 11:38:43 UTC",
      drawDistributionAsOn: "July 2, 2024",
      dd1: "940",
      dd2: "16,521",
      dd3: "64,761",
      dd4: "10,295",
      dd5: "12,447",
      dd6: "16,371",
      dd7: "13,883",
      dd8: "11,765",
      dd9: "54,148",
      dd10: "11,031",
      dd11: "11,342",
      dd12: "9,947",
      dd13: "10,876",
      dd14: "10,952",
      dd15: "54,902",
      dd16: "25,752",
      dd17: "5,523",
      dd18: "222,547",
    },
    {
      drawNumber: "298",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=298'>298</a>",
      drawDate: "2024-06-19",
      drawDateFull: "June 19, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "1,499",
      drawCRS: "663",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=298'>Invitations to apply for permanent residence under the Express Entry system #298</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=298'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "June 19, 2024 at 14:37:26 UTC",
      drawCutOff: "January 19, 2024 at 10:10:40 UTC",
      drawDistributionAsOn: "May 30, 2024",
      dd1: "3,002",
      dd2: "14,330",
      dd3: "63,451",
      dd4: "8,880",
      dd5: "12,132",
      dd6: "16,800",
      dd7: "13,840",
      dd8: "11,799",
      dd9: "54,200",
      dd10: "11,026",
      dd11: "11,086",
      dd12: "9,989",
      dd13: "10,927",
      dd14: "11,172",
      dd15: "57,086",
      dd16: "27,247",
      dd17: "5,624",
      dd18: "224,940",
    },
    {
      drawNumber: "297",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=297'>297</a>",
      drawDate: "2024-05-31",
      drawDateFull: "May 31, 2024",
      drawName: "Canadian Experience Class",
      drawSize: "3,000",
      drawCRS: "522",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=297'>Invitations to apply for permanent residence under the Express Entry system #297</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=297'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "May 31, 2024 at12:48:30 UTC",
      drawCutOff: "March 19, 2024 at 18:58:58 UTC",
      drawDistributionAsOn: "May 29, 2024",
      dd1: "2,918",
      dd2: "14,211",
      dd3: "63,353",
      dd4: "8,855",
      dd5: "12,120",
      dd6: "16,805",
      dd7: "13,828",
      dd8: "11,745",
      dd9: "54,078",
      dd10: "11,022",
      dd11: "11,023",
      dd12: "9,977",
      dd13: "10,900",
      dd14: "11,156",
      dd15: "57,028",
      dd16: "27,210",
      dd17: "5,606",
      dd18: "224,404",
    },
    {
      drawNumber: "296",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=296'>296</a>",
      drawDate: "2024-05-30",
      drawDateFull: "May 30, 2024",
      drawName: "Provincial Nominee Program",
      drawSize: "2,985",
      drawCRS: "676",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=296'>Invitations to apply for permanent residence under the Express Entry system #296</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=296'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 30, 2024 at 14:59:31 UTC",
      drawCutOff: "January 19, 2024 at 18:32:21 UTC",
      drawDistributionAsOn: "May 29, 2024",
      dd1: "2,918",
      dd2: "14,211",
      dd3: "63,353",
      dd4: "8,855",
      dd5: "12,120",
      dd6: "16,805",
      dd7: "13,828",
      dd8: "11,745",
      dd9: "54,078",
      dd10: "11,022",
      dd11: "11,023",
      dd12: "9,977",
      dd13: "10,900",
      dd14: "11,156",
      dd15: "57,028",
      dd16: "27,210",
      dd17: "5,606",
      dd18: "224,404",
    },
    {
      drawNumber: "295",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=295'>295</a>",
      drawDate: "2024-04-24",
      drawDateFull: "April 24, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "1,400",
      drawCRS: "410",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=295'>Invitations to apply for permanent residence under the Express Entry system #295</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=295'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "April 24, 2024 at 14:10:42 UTC",
      drawCutOff: "April 07, 2024 at 02:06:46 UTC",
      drawDistributionAsOn: "April 23, 2024",
      dd1: "1,057",
      dd2: "10,625",
      dd3: "60,933",
      dd4: "7,520",
      dd5: "11,495",
      dd6: "16,928",
      dd7: "13,558",
      dd8: "11,432",
      dd9: "52,475",
      dd10: "10,646",
      dd11: "10,645",
      dd12: "9,645",
      dd13: "10,740",
      dd14: "10,799",
      dd15: "56,383",
      dd16: "27,740",
      dd17: "5,484",
      dd18: "214,697",
    },
    {
      drawNumber: "294",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=294'>294</a>",
      drawDate: "2024-04-23",
      drawDateFull: "April 23, 2024",
      drawName: "General",
      drawSize: "2,095",
      drawCRS: "529",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=294'>Invitations to apply for permanent residence under the Express Entry system #294</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=294'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "April 23, 2024 at 16:54:59 UTC",
      drawCutOff: "November 08, 2023 at 17:50:29 UTC",
      drawDistributionAsOn: "April 23, 2024",
      dd1: "1,057",
      dd2: "10,625",
      dd3: "60,933",
      dd4: "7,520",
      dd5: "11,495",
      dd6: "16,928",
      dd7: "13,558",
      dd8: "11,432",
      dd9: "52,475",
      dd10: "10,646",
      dd11: "10,645",
      dd12: "9,645",
      dd13: "10,740",
      dd14: "10,799",
      dd15: "56,383",
      dd16: "27,740",
      dd17: "5,484",
      dd18: "214,697",
    },
    {
      drawNumber: "293",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=293'>293</a>",
      drawDate: "2024-04-11",
      drawDateFull: "April 11, 2024",
      drawName: "STEM occupations (Version 1)",
      drawSize: "4,500",
      drawCRS: "491",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=293'>Invitations to apply for permanent residence under the Express Entry system #293</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=293'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "April 11, 2024 at 13:23:42 UTC",
      drawCutOff: "February 25, 2024 at 11:55:12 UTC",
      drawDistributionAsOn: "April 9, 2024",
      dd1: "1,093",
      dd2: "12,051",
      dd3: "62,073",
      dd4: "9,167",
      dd5: "11,246",
      dd6: "16,933",
      dd7: "13,429",
      dd8: "11,298",
      dd9: "51,694",
      dd10: "10,454",
      dd11: "10,485",
      dd12: "9,461",
      dd13: "10,640",
      dd14: "10,654",
      dd15: "56,015",
      dd16: "27,831",
      dd17: "5,410",
      dd18: "216,167",
    },
    {
      drawNumber: "292",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=292'>292</a>",
      drawDate: "2024-04-10",
      drawDateFull: "April 10, 2024",
      drawName: "General",
      drawSize: "1,280",
      drawCRS: "549",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=292'>Invitations to apply for permanent residence under the Express Entry system #292</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=292'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "April 10, 2024 at 13:11:33 UTC",
      drawCutOff: "March 21, 2024 at 03:37:24 UTC",
      drawDistributionAsOn: "April 9, 2024",
      dd1: "1,093",
      dd2: "12,051",
      dd3: "62,073",
      dd4: "9,167",
      dd5: "11,246",
      dd6: "16,933",
      dd7: "13,429",
      dd8: "11,298",
      dd9: "51,694",
      dd10: "10,454",
      dd11: "10,485",
      dd12: "9,461",
      dd13: "10,640",
      dd14: "10,654",
      dd15: "56,015",
      dd16: "27,831",
      dd17: "5,410",
      dd18: "216,167",
    },
    {
      drawNumber: "291",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=291'>291</a>",
      drawDate: "2024-03-26",
      drawDateFull: "March 26, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "1,500",
      drawCRS: "388",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=291'>Invitations to apply for permanent residence under the Express Entry system #291</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=291'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "March 26, 2024 at 15:59:36 UTC",
      drawCutOff: "March 10, 2024 at 20:06:26 UTC",
      drawDistributionAsOn: "March 24, 2024",
      dd1: "987",
      dd2: "10,342",
      dd3: "60,488",
      dd4: "8,294",
      dd5: "10,683",
      dd6: "17,105",
      dd7: "13,218",
      dd8: "11,188",
      dd9: "50,882",
      dd10: "10,172",
      dd11: "10,309",
      dd12: "9,296",
      dd13: "10,610",
      dd14: "10,495",
      dd15: "55,586",
      dd16: "28,287",
      dd17: "5,390",
      dd18: "211,962",
    },
    {
      drawNumber: "290",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=290'>290</a>",
      drawDate: "2024-03-25",
      drawDateFull: "March 25, 2024",
      drawName: "General",
      drawSize: "1,980",
      drawCRS: "524",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=290'>Invitations to apply for permanent residence under the Express Entry system #290</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=290'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "March 25, 2024 at 17:45:49 UTC",
      drawCutOff: "February 25, 2024 at 14:42:38 UTC",
      drawDistributionAsOn: "March 24, 2024",
      dd1: "987",
      dd2: "10,342",
      dd3: "60,488",
      dd4: "8,294",
      dd5: "10,683",
      dd6: "17,105",
      dd7: "13,218",
      dd8: "11,188",
      dd9: "50,882",
      dd10: "10,172",
      dd11: "10,309",
      dd12: "9,296",
      dd13: "10,610",
      dd14: "10,495",
      dd15: "55,586",
      dd16: "28,287",
      dd17: "5,390",
      dd18: "211,962",
    },
    {
      drawNumber: "289",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=289'>289</a>",
      drawDate: "2024-03-13",
      drawDateFull: "March 13, 2024",
      drawName: "Transport occupations (Version 1)",
      drawSize: "975",
      drawCRS: "430",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=289'>Invitations to apply for permanent residence under the Express Entry system #289</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=289'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "March 13, 2024 at 16:16:01 UTC",
      drawCutOff: "July 31, 2023 at 13:27:26 UTC",
      drawDistributionAsOn: "March 11, 2024",
      dd1: "987",
      dd2: "10,573",
      dd3: "60,108",
      dd4: "8,065",
      dd5: "10,499",
      dd6: "17,083",
      dd7: "13,275",
      dd8: "11,186",
      dd9: "50,569",
      dd10: "10,174",
      dd11: "10,410",
      dd12: "9,186",
      dd13: "10,414",
      dd14: "10,385",
      dd15: "55,099",
      dd16: "28,536",
      dd17: "5,348",
      dd18: "211,220",
    },
    {
      drawNumber: "288",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=288'>288</a>",
      drawDate: "2024-03-12",
      drawDateFull: "March 12, 2024",
      drawName: "General",
      drawSize: "2,850",
      drawCRS: "525",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=288'>Invitations to apply for permanent residence under the Express Entry system #288</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=288'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "March 12, 2024 at 14:57:26 UTC",
      drawCutOff: "November 01, 2023 at 17:08:51 UTC",
      drawDistributionAsOn: "March 11, 2024",
      dd1: "987",
      dd2: "10,573",
      dd3: "60,108",
      dd4: "8,065",
      dd5: "10,499",
      dd6: "17,083",
      dd7: "13,275",
      dd8: "11,186",
      dd9: "50,569",
      dd10: "10,174",
      dd11: "10,410",
      dd12: "9,186",
      dd13: "10,414",
      dd14: "10,385",
      dd15: "55,099",
      dd16: "28,536",
      dd17: "5,348",
      dd18: "211,220",
    },
    {
      drawNumber: "287",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=287'>287</a>",
      drawDate: "2024-02-29",
      drawDateFull: "February 29, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "2,500",
      drawCRS: "336",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=287'>Invitations to apply for permanent residence under the Express Entry system #287</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=287'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "February 29, 2024 at 15:58:53 UTC",
      drawCutOff: "January 18, 2024 at 09:15:46 UTC",
      drawDistributionAsOn: "February 27, 2024",
      dd1: "965",
      dd2: "9,567",
      dd3: "59,371",
      dd4: "7,574",
      dd5: "10,095",
      dd6: "17,137",
      dd7: "13,373",
      dd8: "11,192",
      dd9: "50,695",
      dd10: "10,265",
      dd11: "10,474",
      dd12: "9,110",
      dd13: "10,407",
      dd14: "10,439",
      dd15: "56,178",
      dd16: "29,305",
      dd17: "5,406",
      dd18: "211,487",
    },
    {
      drawNumber: "286",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=286'>286</a>",
      drawDate: "2024-02-28",
      drawDateFull: "February 28, 2024",
      drawName: "General",
      drawSize: "1,470",
      drawCRS: "534",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=286'>Invitations to apply for permanent residence under the Express Entry system #286</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=286'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "February 28, 2024 at 14:57:26 UTC",
      drawCutOff: "January 16, 2024 at 03:51:05 UTC",
      drawDistributionAsOn: "February 27, 2024",
      dd1: "965",
      dd2: "9,567",
      dd3: "59,371",
      dd4: "7,574",
      dd5: "10,095",
      dd6: "17,137",
      dd7: "13,373",
      dd8: "11,192",
      dd9: "50,695",
      dd10: "10,265",
      dd11: "10,474",
      dd12: "9,110",
      dd13: "10,407",
      dd14: "10,439",
      dd15: "56,178",
      dd16: "29,305",
      dd17: "5,406",
      dd18: "211,487",
    },
    {
      drawNumber: "285",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=285'>285</a>",
      drawDate: "2024-02-16",
      drawDateFull: "February 16, 2024",
      drawName: "Agriculture and agri-food occupations (Version 1)",
      drawSize: "150",
      drawCRS: "437",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=285'>Invitations to apply for permanent residence under the Express Entry system #285</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=285'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "February 16, 2024 at 15:18:05 UTC",
      drawCutOff: "January 29, 2024 at 03:32:58 UTC",
      drawDistributionAsOn: "February 11, 2024",
      dd1: "587",
      dd2: "9,004",
      dd3: "60,043",
      dd4: "7,285",
      dd5: "10,016",
      dd6: "17,431",
      dd7: "13,750",
      dd8: "11,561",
      dd9: "51,356",
      dd10: "10,514",
      dd11: "10,752",
      dd12: "9,445",
      dd13: "10,283",
      dd14: "10,362",
      dd15: "55,850",
      dd16: "29,141",
      dd17: "5,292",
      dd18: "211,273",
    },
    {
      drawNumber: "284",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=284'>284</a>",
      drawDate: "2024-02-14",
      drawDateFull: "February 14, 2024",
      drawName: "Healthcare occupations (Version 1)",
      drawSize: "3,500",
      drawCRS: "422",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=284'>Invitations to apply for permanent residence under the Express Entry system #284</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=284'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "February 14, 2024 at 15:50:22 UTC",
      drawCutOff: "January 30, 2024 at 02:39:54 UTC",
      drawDistributionAsOn: "February 11, 2024",
      dd1: "587",
      dd2: "9,004",
      dd3: "60,043",
      dd4: "7,285",
      dd5: "10,016",
      dd6: "17,431",
      dd7: "13,750",
      dd8: "11,561",
      dd9: "51,356",
      dd10: "10,514",
      dd11: "10,752",
      dd12: "9,445",
      dd13: "10,283",
      dd14: "10,362",
      dd15: "55,850",
      dd16: "29,141",
      dd17: "5,292",
      dd18: "211,273",
    },
    {
      drawNumber: "283",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=283'>283</a>",
      drawDate: "2024-02-13",
      drawDateFull: "February 13, 2024",
      drawName: "General",
      drawSize: "1,490",
      drawCRS: "535",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=283'>Invitations to apply for permanent residence under the Express Entry system #283</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=283'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "February 13, 2024 at 14:18:53 UTC",
      drawCutOff: "February 11, 2024 at 15:26:56 UTC",
      drawDistributionAsOn: "February 11, 2024",
      dd1: "587",
      dd2: "9,004",
      dd3: "60,043",
      dd4: "7,285",
      dd5: "10,016",
      dd6: "17,431",
      dd7: "13,750",
      dd8: "11,561",
      dd9: "51,356",
      dd10: "10,514",
      dd11: "10,752",
      dd12: "9,445",
      dd13: "10,283",
      dd14: "10,362",
      dd15: "55,850",
      dd16: "29,141",
      dd17: "5,292",
      dd18: "211,273",
    },
    {
      drawNumber: "282",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=282'>282</a>",
      drawDate: "2024-02-01",
      drawDateFull: "February 1, 2024",
      drawName: "French language proficiency (Version 1)",
      drawSize: "7,000",
      drawCRS: "365",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=282'>Invitations to apply for permanent residence under the Express Entry system #282</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=282'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "February 01, 2024 at 16:36:01 UTC",
      drawCutOff: "September 08, 2023 at 09:47:27 UTC",
      drawDistributionAsOn: "January 30, 2024",
      dd1: "534",
      dd2: "7,926",
      dd3: "60,120",
      dd4: "6,787",
      dd5: "9,668",
      dd6: "17,586",
      dd7: "14,160",
      dd8: "11,919",
      dd9: "54,181",
      dd10: "10,930",
      dd11: "11,219",
      dd12: "10,104",
      dd13: "10,874",
      dd14: "11,054",
      dd15: "57,855",
      dd16: "29,709",
      dd17: "5,401",
      dd18: "215,726",
    },
    {
      drawNumber: "281",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=281'>281</a>",
      drawDate: "2024-01-31",
      drawDateFull: "January 31, 2024",
      drawName: "General",
      drawSize: "730",
      drawCRS: "541",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=281'>Invitations to apply for permanent residence under the Express Entry system #281</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=281'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "January 31, 2024 at 15:18:02 UTC",
      drawCutOff: "July 15, 2023 at 10:52:08 UTC",
      drawDistributionAsOn: "January 30, 2024",
      dd1: "534",
      dd2: "7,926",
      dd3: "60,120",
      dd4: "6,787",
      dd5: "9,668",
      dd6: "17,586",
      dd7: "14,160",
      dd8: "11,919",
      dd9: "54,181",
      dd10: "10,930",
      dd11: "11,219",
      dd12: "10,104",
      dd13: "10,874",
      dd14: "11,054",
      dd15: "57,855",
      dd16: "29,709",
      dd17: "5,401",
      dd18: "215,726",
    },
    {
      drawNumber: "280",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=280'>280</a>",
      drawDate: "2024-01-23",
      drawDateFull: "January 23, 2024",
      drawName: "General",
      drawSize: "1,040",
      drawCRS: "543",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=280'>Invitations to apply for permanent residence under the Express Entry system #280</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=280'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "January 23, 2024 at 16:08:03 UTC",
      drawCutOff: "January 21, 2024 at 07:43:47 UTC",
      drawDistributionAsOn: "January 22, 2024",
      dd1: "646",
      dd2: "6,915",
      dd3: "59,043",
      dd4: "6,399",
      dd5: "9,291",
      dd6: "17,395",
      dd7: "14,189",
      dd8: "11,769",
      dd9: "53,905",
      dd10: "10,942",
      dd11: "11,131",
      dd12: "10,053",
      dd13: "10,801",
      dd14: "10,978",
      dd15: "57,979",
      dd16: "29,690",
      dd17: "5,348",
      dd18: "213,526",
    },
    {
      drawNumber: "279",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=279'>279</a>",
      drawDate: "2024-01-10",
      drawDateFull: "January 10, 2024",
      drawName: "General",
      drawSize: "1,510",
      drawCRS: "546",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=279'>Invitations to apply for permanent residence under the Express Entry system #279</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=279'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "January 10, 2024 at 16:59:13 UTC",
      drawCutOff: "December 31, 2023 at 18:23:22 UTC",
      drawDistributionAsOn: "January 9, 2024",
      dd1: "1,298",
      dd2: "6,345",
      dd3: "58,298",
      dd4: "6,164",
      dd5: "9,042",
      dd6: "17,311",
      dd7: "14,118",
      dd8: "11,663",
      dd9: "53,671",
      dd10: "10,863",
      dd11: "11,072",
      dd12: "10,038",
      dd13: "10,728",
      dd14: "10,970",
      dd15: "57,703",
      dd16: "29,542",
      dd17: "5,300",
      dd18: "212,157",
    },
    {
      drawNumber: "278",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=278'>278</a>",
      drawDate: "2023-12-21",
      drawDateFull: "December 21, 2023",
      drawName: "Agriculture and agri-food occupations (Version 1)",
      drawSize: "400",
      drawCRS: "386",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=278'>Invitations to apply for permanent residence under the Express Entry system #278</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=278'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "December 21, 2023 at 14:15:42 UTC",
      drawCutOff: "July 06, 2023 at 05:44:14 UTC",
      drawDistributionAsOn: "December 17, 2023",
      dd1: "1,011",
      dd2: "4,433",
      dd3: "56,223",
      dd4: "5,140",
      dd5: "8,041",
      dd6: "17,221",
      dd7: "14,219",
      dd8: "11,602",
      dd9: "53,698",
      dd10: "10,946",
      dd11: "11,144",
      dd12: "10,055",
      dd13: "10,606",
      dd14: "10,947",
      dd15: "58,033",
      dd16: "29,955",
      dd17: "5,285",
      dd18: "208,638",
    },
    {
      drawNumber: "277",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=277'>277</a>",
      drawDate: "2023-12-20",
      drawDateFull: "December 20, 2023",
      drawName: "Transport occupations (Version 1)",
      drawSize: "670",
      drawCRS: "435",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=277'>Invitations to apply for permanent residence under the Express Entry system #277</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=277'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "December 20, 2023 at 15:55:23 UTC",
      drawCutOff: "August 30. 2023 at 17:30:21 UTC",
      drawDistributionAsOn: "December 17, 2023",
      dd1: "1,011",
      dd2: "4,433",
      dd3: "56,223",
      dd4: "5,140",
      dd5: "8,041",
      dd6: "17,221",
      dd7: "14,219",
      dd8: "11,602",
      dd9: "53,698",
      dd10: "10,946",
      dd11: "11,144",
      dd12: "10,055",
      dd13: "10,606",
      dd14: "10,947",
      dd15: "58,033",
      dd16: "29,955",
      dd17: "5,285",
      dd18: "208,638",
    },
    {
      drawNumber: "276",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=276'>276</a>",
      drawDate: "2023-12-19",
      drawDateFull: "December 19, 2023",
      drawName: "Trade occupations (Version 1)",
      drawSize: "1,000",
      drawCRS: "425",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=276'>Invitations to apply for permanent residence under the Express Entry system #276</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=276'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "December 19, 2023 at 14:44:00 UTC",
      drawCutOff: "November 04, 2023 at 11:16:58 UTC",
      drawDistributionAsOn: "December 17, 2023",
      dd1: "1,011",
      dd2: "4,433",
      dd3: "56,223",
      dd4: "5,140",
      dd5: "8,041",
      dd6: "17,221",
      dd7: "14,219",
      dd8: "11,602",
      dd9: "53,698",
      dd10: "10,946",
      dd11: "11,144",
      dd12: "10,055",
      dd13: "10,606",
      dd14: "10,947",
      dd15: "58,033",
      dd16: "29,955",
      dd17: "5,285",
      dd18: "208,638",
    },
    {
      drawNumber: "275",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=275'>275</a>",
      drawDate: "2023-12-18",
      drawDateFull: "December 18, 2023",
      drawName: "General",
      drawSize: "1,325",
      drawCRS: "542",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=275'>Invitations to apply for permanent residence under the Express Entry system #275</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=275'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "December 18, 2023 at 16:12:22 UTC",
      drawCutOff: "November 06, 2023 at 14:40:23 UTC",
      drawDistributionAsOn: "December 17, 2023",
      dd1: "1,011",
      dd2: "4,433",
      dd3: "56,223",
      dd4: "5,140",
      dd5: "8,041",
      dd6: "17,221",
      dd7: "14,219",
      dd8: "11,602",
      dd9: "53,698",
      dd10: "10,946",
      dd11: "11,144",
      dd12: "10,055",
      dd13: "10,606",
      dd14: "10,947",
      dd15: "58,033",
      dd16: "29,955",
      dd17: "5,285",
      dd18: "208,638",
    },
    {
      drawNumber: "274",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=274'>274</a>",
      drawDate: "2023-12-08",
      drawDateFull: "December 8, 2023",
      drawName: "STEM occupations (Version 1)",
      drawSize: "5,900",
      drawCRS: "481",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=274'>Invitations to apply for permanent residence under the Express Entry system #274</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=274'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "December 08, 2023 at 03:02:52 UTC",
      drawCutOff: "September 07, 2023 at 09:24:49 UTC",
      drawDistributionAsOn: "December 5, 2023",
      dd1: "4,525",
      dd2: "5,457",
      dd3: "60,014",
      dd4: "6,634",
      dd5: "10,335",
      dd6: "17,312",
      dd7: "14,165",
      dd8: "11,568",
      dd9: "53,502",
      dd10: "10,885",
      dd11: "11,111",
      dd12: "9,973",
      dd13: "10,608",
      dd14: "10,925",
      dd15: "58,457",
      dd16: "30,148",
      dd17: "5,267",
      dd18: "217,370",
    },
    {
      drawNumber: "273",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=273'>273</a>",
      drawDate: "2023-12-07",
      drawDateFull: "December 7, 2023",
      drawName: "French language proficiency (Version 1)",
      drawSize: "1,000",
      drawCRS: "470",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=273'>Invitations to apply for permanent residence under the Express Entry system #273</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=273'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "December 07, 2023 at 16:35:25 UTC",
      drawCutOff: "October 03, 2023 at 04:45:27 UTC",
      drawDistributionAsOn: "December 5, 2023",
      dd1: "4,525",
      dd2: "5,457",
      dd3: "60,014",
      dd4: "6,634",
      dd5: "10,335",
      dd6: "17,312",
      dd7: "14,165",
      dd8: "11,568",
      dd9: "53,502",
      dd10: "10,885",
      dd11: "11,111",
      dd12: "9,973",
      dd13: "10,608",
      dd14: "10,925",
      dd15: "58,457",
      dd16: "30,148",
      dd17: "5,267",
      dd18: "217,370",
    },
    {
      drawNumber: "272",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=272'>272</a>",
      drawDate: "2023-12-06",
      drawDateFull: "December 6, 2023",
      drawName: "General",
      drawSize: "4,750",
      drawCRS: "561",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=272'>Invitations to apply for permanent residence under the Express Entry system #272</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=272'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "December 6, 2023 at 16:27:26 UTC",
      drawCutOff: "November 08, 2023 at 06:00:13 UTC",
      drawDistributionAsOn: "December 5, 2023",
      dd1: "4,525",
      dd2: "5,457",
      dd3: "60,014",
      dd4: "6,634",
      dd5: "10,335",
      dd6: "17,312",
      dd7: "14,165",
      dd8: "11,568",
      dd9: "53,502",
      dd10: "10,885",
      dd11: "11,111",
      dd12: "9,973",
      dd13: "10,608",
      dd14: "10,925",
      dd15: "58,457",
      dd16: "30,148",
      dd17: "5,267",
      dd18: "217,370",
    },
    {
      drawNumber: "271",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=271'>271</a>",
      drawDate: "2023-10-26",
      drawDateFull: "October 26, 2023",
      drawName: "Healthcare occupations (Version 1)",
      drawSize: "3,600",
      drawCRS: "431",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=271'>Invitations to apply for permanent residence under the Express Entry system #271</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=271'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "October 26, 2023 at 13:28:39 UTC",
      drawCutOff: "May 24, 2023 at 19:47:51 UTC",
      drawDistributionAsOn: "October 23, 2023",
      dd1: "1,536",
      dd2: "1,307",
      dd3: "60,587",
      dd4: "4,853",
      dd5: "9,514",
      dd6: "18,836",
      dd7: "15,063",
      dd8: "12,321",
      dd9: "54,565",
      dd10: "11,256",
      dd11: "11,705",
      dd12: "9,926",
      dd13: "10,525",
      dd14: "11,153",
      dd15: "60,378",
      dd16: "31,189",
      dd17: "5,311",
      dd18: "214,873",
    },
    {
      drawNumber: "270",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=270'>270</a>",
      drawDate: "2023-10-25",
      drawDateFull: "October 25, 2023",
      drawName: "French language proficiency (Version 1)",
      drawSize: "300",
      drawCRS: "486",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=270'>Invitations to apply for permanent residence under the Express Entry system #270</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=270'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "October 25, 2023 at 15:48:39 AM UTC",
      drawCutOff: "September 30, 2023 at 04:22:17 AM UTC",
      drawDistributionAsOn: "October 23, 2023",
      dd1: "1,536",
      dd2: "1,307",
      dd3: "60,587",
      dd4: "4,853",
      dd5: "9,514",
      dd6: "18,836",
      dd7: "15,063",
      dd8: "12,321",
      dd9: "54,565",
      dd10: "11,256",
      dd11: "11,705",
      dd12: "9,926",
      dd13: "10,525",
      dd14: "11,153",
      dd15: "60,378",
      dd16: "31,189",
      dd17: "5,311",
      dd18: "214,873",
    },
    {
      drawNumber: "269",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=269'>269</a>",
      drawDate: "2023-10-24",
      drawDateFull: "October 24, 2023",
      drawName: "Provincial Nominee Program",
      drawSize: "1,548",
      drawCRS: "776",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=269'>Invitations to apply for permanent residence under the Express Entry system #269</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=269'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "October 24, 2023 at 14:23:38 UTC",
      drawCutOff: "July 07, 2023 at 07:28:57 UTC",
      drawDistributionAsOn: "October 23, 2023",
      dd1: "1,536",
      dd2: "1,307",
      dd3: "60,587",
      dd4: "4,853",
      dd5: "9,514",
      dd6: "18,836",
      dd7: "15,063",
      dd8: "12,321",
      dd9: "54,565",
      dd10: "11,256",
      dd11: "11,705",
      dd12: "9,926",
      dd13: "10,525",
      dd14: "11,153",
      dd15: "60,378",
      dd16: "31,189",
      dd17: "5,311",
      dd18: "214,873",
    },
    {
      drawNumber: "268",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=268'>268</a>",
      drawDate: "2023-10-10",
      drawDateFull: "October 10, 2023",
      drawName: "No Program Specified",
      drawSize: "3,725",
      drawCRS: "500",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=268'>Invitations to apply for permanent residence under the Express Entry system #268</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=268'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "October 10, 2023 at 15:49:55 UTC",
      drawCutOff: "October 02, 2023 at 15:25:30 UTC",
      drawDistributionAsOn: "October 10, 2023",
      dd1: "1,210",
      dd2: "2,237",
      dd3: "59,674",
      dd4: "4,508",
      dd5: "9,121",
      dd6: "18,794",
      dd7: "15,016",
      dd8: "12,235",
      dd9: "54,162",
      dd10: "11,163",
      dd11: "11,580",
      dd12: "9,844",
      dd13: "10,484",
      dd14: "11,091",
      dd15: "60,078",
      dd16: "30,996",
      dd17: "5,235",
      dd18: "213,592",
    },
    {
      drawNumber: "267",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=267'>267</a>",
      drawDate: "2023-09-28",
      drawDateFull: "September 28, 2023",
      drawName: "Agriculture and agri-food occupations (Version 1)",
      drawSize: "600",
      drawCRS: "354",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=267'>Invitations to apply for permanent residence under the Express Entry system #267</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=267'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "September 28, 2023 at 16:45:09 UTC",
      drawCutOff: "June 12, 2023 at 10:23:21 UTC",
      drawDistributionAsOn: "September 26, 2023",
      dd1: "544",
      dd2: "2,923",
      dd3: "58,645",
      dd4: "3,727",
      dd5: "8,544",
      dd6: "19,143",
      dd7: "15,048",
      dd8: "12,183",
      dd9: "54,225",
      dd10: "11,205",
      dd11: "11,590",
      dd12: "9,810",
      dd13: "10,478",
      dd14: "11,142",
      dd15: "60,843",
      dd16: "31,532",
      dd17: "5,288",
      dd18: "214,000",
    },
    {
      drawNumber: "266",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=266'>266</a>",
      drawDate: "2023-09-27",
      drawDateFull: "September 27, 2023",
      drawName: "French language proficiency (Version 1)",
      drawSize: "500",
      drawCRS: "472",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=266'>Invitations to apply for permanent residence under the Express Entry system #266</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=266'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "September 27, 2023 at 14:17:04 UTC",
      drawCutOff: "August 16, 2023 at 09:00:14 UTC",
      drawDistributionAsOn: "September 26, 2023",
      dd1: "544",
      dd2: "2,923",
      dd3: "58,645",
      dd4: "3,727",
      dd5: "8,544",
      dd6: "19,143",
      dd7: "15,048",
      dd8: "12,183",
      dd9: "54,225",
      dd10: "11,205",
      dd11: "11,590",
      dd12: "9,810",
      dd13: "10,478",
      dd14: "11,142",
      dd15: "60,843",
      dd16: "31,532",
      dd17: "5,288",
      dd18: "214,000",
    },
    {
      drawNumber: "265",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=265'>265</a>",
      drawDate: "2023-09-26",
      drawDateFull: "September 26, 2023",
      drawName: "No Program Specified",
      drawSize: "3,000",
      drawCRS: "504",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=265'>Invitations to apply for permanent residence under the Express Entry system #265</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=265'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "September 26, 2023 at 13:38:55 UTC",
      drawCutOff: "September 11, 2023 at 20:24:18 UTC",
      drawDistributionAsOn: "September 26, 2023",
      dd1: "544",
      dd2: "2,923",
      dd3: "58,645",
      dd4: "3,727",
      dd5: "8,544",
      dd6: "19,143",
      dd7: "15,048",
      dd8: "12,183",
      dd9: "54,225",
      dd10: "11,205",
      dd11: "11,590",
      dd12: "9,810",
      dd13: "10,478",
      dd14: "11,142",
      dd15: "60,843",
      dd16: "31,532",
      dd17: "5,288",
      dd18: "214,000",
    },
    {
      drawNumber: "264",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=264'>264</a>",
      drawDate: "2023-09-20",
      drawDateFull: "September 20, 2023",
      drawName: "Transport occupations (Version 1)",
      drawSize: "1,000",
      drawCRS: "435",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=264'>Invitations to apply for permanent residence under the Express Entry system #264</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=264'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "September 20, 2023 at 14:04:14 UTC",
      drawCutOff: "June 17, 2023 at 06:44:01 UTC",
      drawDistributionAsOn: "September 19, 2023",
      dd1: "2,637",
      dd2: "3,091",
      dd3: "58,838",
      dd4: "3,596",
      dd5: "8,510",
      dd6: "19,326",
      dd7: "15,170",
      dd8: "12,236",
      dd9: "54,240",
      dd10: "11,331",
      dd11: "11,601",
      dd12: "9,813",
      dd13: "10,414",
      dd14: "11,081",
      dd15: "60,575",
      dd16: "31,418",
      dd17: "5,236",
      dd18: "216,035",
    },
    {
      drawNumber: "263",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=263'>263</a>",
      drawDate: "2023-09-19",
      drawDateFull: "September 19, 2023",
      drawName: "No Program Specified",
      drawSize: "3,200",
      drawCRS: "531",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=263'>Invitations to apply for permanent residence under the Express Entry system #263</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=263'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "September 19, 2023 at 15:04:11 UTC",
      drawCutOff: "September 12, 2023 at 12:16:31 UTC",
      drawDistributionAsOn: "September 19, 2023",
      dd1: "2,637",
      dd2: "3,091",
      dd3: "58,838",
      dd4: "3,596",
      dd5: "8,510",
      dd6: "19,326",
      dd7: "15,170",
      dd8: "12,236",
      dd9: "54,240",
      dd10: "11,331",
      dd11: "11,601",
      dd12: "9,813",
      dd13: "10,414",
      dd14: "11,081",
      dd15: "60,575",
      dd16: "31,418",
      dd17: "5,236",
      dd18: "216,035",
    },
    {
      drawNumber: "262",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=262'>262</a>",
      drawDate: "2023-08-15",
      drawDateFull: "August 15, 2023",
      drawName: "No Program Specified",
      drawSize: "4,300",
      drawCRS: "496",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=262'>Invitations to apply for permanent residence under the Express Entry system #262</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=262'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "August 15, 2023 at 14:23:51 UTC",
      drawCutOff: "July 04, 2023 at 19:36:18 UTC ",
      drawDistributionAsOn: "August 15, 2023",
      dd1: "984",
      dd2: "2,172",
      dd3: "55,924",
      dd4: "2,709",
      dd5: "6,721",
      dd6: "19,351",
      dd7: "14,976",
      dd8: "12,167",
      dd9: "53,709",
      dd10: "11,184",
      dd11: "11,596",
      dd12: "9,573",
      dd13: "10,282",
      dd14: "11,074",
      dd15: "61,289",
      dd16: "31,980",
      dd17: "5,298",
      dd18: "211,356",
    },
    {
      drawNumber: "261",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=261'>261</a>",
      drawDate: "2023-08-03",
      drawDateFull: "August 3, 2023",
      drawName: "Trade occupations (Version 1)",
      drawSize: "1,500",
      drawCRS: "388",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=261'>Invitations to apply for permanent residence under the Express Entry system #261</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=261'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "August 03, 2023 at 13:24:52 UTC",
      drawCutOff: "June 07, 2023 at 07:03:08 UTC",
      drawDistributionAsOn: "August 1, 2023",
      dd1: "1,308",
      dd2: "2,019",
      dd3: "55,295",
      dd4: "2,286",
      dd5: "6,236",
      dd6: "19,398",
      dd7: "15,100",
      dd8: "12,275",
      dd9: "54,173",
      dd10: "11,293",
      dd11: "11,721",
      dd12: "9,618",
      dd13: "10,369",
      dd14: "11,172",
      dd15: "61,438",
      dd16: "32,077",
      dd17: "5,219",
      dd18: "211,529",
    },
    {
      drawNumber: "260",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=260'>260</a>",
      drawDate: "2023-08-02",
      drawDateFull: "August 2, 2023",
      drawName: "French language proficiency (Version 1)",
      drawSize: "800",
      drawCRS: "435",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=260'>Invitations to apply for permanent residence under the Express Entry system #260</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=260'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "August 02, 2023 at 13:50:04 UTC",
      drawCutOff: "July 31, 2023 at 09:57:30 UTC",
      drawDistributionAsOn: "August 1, 2023",
      dd1: "1,308",
      dd2: "2,019",
      dd3: "55,295",
      dd4: "2,286",
      dd5: "6,236",
      dd6: "19,398",
      dd7: "15,100",
      dd8: "12,275",
      dd9: "54,173",
      dd10: "11,293",
      dd11: "11,721",
      dd12: "9,618",
      dd13: "10,369",
      dd14: "11,172",
      dd15: "61,438",
      dd16: "32,077",
      dd17: "5,219",
      dd18: "211,529",
    },
    {
      drawNumber: "259",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=259'>259</a>",
      drawDate: "2023-08-01",
      drawDateFull: "August 1, 2023",
      drawName: "No Program Specified",
      drawSize: "2,000",
      drawCRS: "517",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=259'>Invitations to apply for permanent residence under the Express Entry system #259</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=259'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "August 01, 2023 at 13:25:02 UTC",
      drawCutOff: "June 30, 2023 at 11:45:05 UTC",
      drawDistributionAsOn: "August 1, 2023",
      dd1: "1,308",
      dd2: "2,019",
      dd3: "55,295",
      dd4: "2,286",
      dd5: "6,236",
      dd6: "19,398",
      dd7: "15,100",
      dd8: "12,275",
      dd9: "54,173",
      dd10: "11,293",
      dd11: "11,721",
      dd12: "9,618",
      dd13: "10,369",
      dd14: "11,172",
      dd15: "61,438",
      dd16: "32,077",
      dd17: "5,219",
      dd18: "211,529",
    },
    {
      drawNumber: "258",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=258'>258</a>",
      drawDate: "2023-07-12",
      drawDateFull: "July 12, 2023",
      drawName: "French language proficiency (Version 1)",
      drawSize: "3,800",
      drawCRS: "375",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=258'>Invitations to apply for permanent residence under the Express Entry system #258</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=258'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "July 12, 2023 at 13:36:01 UTC",
      drawCutOff: "June 15, 2023 at 23:49:05",
      drawDistributionAsOn: "July 11, 2023",
      dd1: "276",
      dd2: "619",
      dd3: "51,689",
      dd4: "834",
      dd5: "4,686",
      dd6: "19,234",
      dd7: "14,827",
      dd8: "12,108",
      dd9: "55,570",
      dd10: "11,164",
      dd11: "12,162",
      dd12: "9,896",
      dd13: "10,775",
      dd14: "11,573",
      dd15: "63,039",
      dd16: "32,771",
      dd17: "5,299",
      dd18: "209,263",
    },
    {
      drawNumber: "257",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=257'>257</a>",
      drawDate: "2023-07-11",
      drawDateFull: "July 11, 2023",
      drawName: "No Program Specified",
      drawSize: "800",
      drawCRS: "505",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=257'>Invitations to apply for permanent residence under the Express Entry system #257</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=257'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "July 11, 2023 at 17:53:49 UTC",
      drawCutOff: "October 01, 2022 at 06:13:05 UTC",
      drawDistributionAsOn: "July 11, 2023",
      dd1: "276",
      dd2: "619",
      dd3: "51,689",
      dd4: "834",
      dd5: "4,686",
      dd6: "19,234",
      dd7: "14,827",
      dd8: "12,108",
      dd9: "55,570",
      dd10: "11,164",
      dd11: "12,162",
      dd12: "9,896",
      dd13: "10,775",
      dd14: "11,573",
      dd15: "63,039",
      dd16: "32,771",
      dd17: "5,299",
      dd18: "209,263",
    },
    {
      drawNumber: "256",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=256'>256</a>",
      drawDate: "2023-07-07",
      drawDateFull: "July 7, 2023",
      drawName: "French language proficiency (Version 1)",
      drawSize: "2,300",
      drawCRS: "439",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=256'>Invitations to apply for permanent residence under the Express Entry system #256</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=256'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "July 07, 2023 at 13:11:16 UTC",
      drawCutOff: "January 20, 2023 at 17:18:32 UTC",
      drawDistributionAsOn: "July 4, 2023",
      dd1: "353",
      dd2: "726",
      dd3: "54,732",
      dd4: "831",
      dd5: "4,719",
      dd6: "20,475",
      dd7: "16,023",
      dd8: "12,684",
      dd9: "56,673",
      dd10: "11,740",
      dd11: "12,309",
      dd12: "10,008",
      dd13: "10,873",
      dd14: "11,743",
      dd15: "63,679",
      dd16: "33,051",
      dd17: "5,315",
      dd18: "214,529",
    },
    {
      drawNumber: "255",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=255'>255</a>",
      drawDate: "2023-07-06",
      drawDateFull: "July 6, 2023",
      drawName: "Healthcare occupations (Version 1)",
      drawSize: "1,500",
      drawCRS: "463",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=255'>Invitations to apply for permanent residence under the Express Entry system #255</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=255'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "July 06, 2023 14:25:05 UTC",
      drawCutOff: "June 19, 2023 06:41:05 UTC",
      drawDistributionAsOn: "July 4, 2023",
      dd1: "353",
      dd2: "726",
      dd3: "54,732",
      dd4: "831",
      dd5: "4,719",
      dd6: "20,475",
      dd7: "16,023",
      dd8: "12,684",
      dd9: "56,673",
      dd10: "11,740",
      dd11: "12,309",
      dd12: "10,008",
      dd13: "10,873",
      dd14: "11,743",
      dd15: "63,679",
      dd16: "33,051",
      dd17: "5,315",
      dd18: "214,529",
    },
    {
      drawNumber: "254",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=254'>254</a>",
      drawDate: "2023-07-05",
      drawDateFull: "July 5, 2023",
      drawName: "STEM occupations (Version 1)",
      drawSize: "500",
      drawCRS: "486",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=254'>Invitations to apply for permanent residence under the Express Entry system #254</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=254'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "July 05, 2023 13:38:22 UTC",
      drawCutOff: "November 23, 2022 00:01:29 UTC",
      drawDistributionAsOn: "July 4, 2023",
      dd1: "353",
      dd2: "726",
      dd3: "54,732",
      dd4: "831",
      dd5: "4,719",
      dd6: "20,475",
      dd7: "16,023",
      dd8: "12,684",
      dd9: "56,673",
      dd10: "11,740",
      dd11: "12,309",
      dd12: "10,008",
      dd13: "10,873",
      dd14: "11,743",
      dd15: "63,679",
      dd16: "33,051",
      dd17: "5,315",
      dd18: "214,529",
    },
    {
      drawNumber: "253",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=253'>253</a>",
      drawDate: "2023-07-04",
      drawDateFull: "July 4, 2023",
      drawName: "No Program Specified",
      drawSize: "700",
      drawCRS: "511",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=253'>Invitations to apply for permanent residence under the Express Entry system #253</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=253'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class, Federal Skilled Trades Program and Provincial Nominee Program",
      drawDateTime: "July 04, 2023 14:23:38 UTC",
      drawCutOff: "July 01, 2023 09:31:32 UTC",
      drawDistributionAsOn: "July 4, 2023",
      dd1: "353",
      dd2: "726",
      dd3: "54,732",
      dd4: "831",
      dd5: "4,719",
      dd6: "20,475",
      dd7: "16,023",
      dd8: "12,684",
      dd9: "56,673",
      dd10: "11,740",
      dd11: "12,309",
      dd12: "10,008",
      dd13: "10,873",
      dd14: "11,743",
      dd15: "63,679",
      dd16: "33,051",
      dd17: "5,315",
      dd18: "214,529",
    },
    {
      drawNumber: "252",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=252'>252</a>",
      drawDate: "2023-06-28",
      drawDateFull: "June 28, 2023",
      drawName: "Healthcare occupations (Version 1)",
      drawSize: "500",
      drawCRS: "476",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=252'>Invitations to apply for permanent residence under the Express Entry system #252</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=252'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker Program, Canadian Experience Class and Federal Skilled Trades Program",
      drawDateTime: "June 28, 2023 14:04:34 UTC",
      drawCutOff: "November 12, 2022 12:47:27 UTC",
      drawDistributionAsOn: "June 28, 2023",
      dd1: "1,254",
      dd2: "1,258",
      dd3: "55,991",
      dd4: "1,040",
      dd5: "4,856",
      dd6: "20,980",
      dd7: "16,226",
      dd8: "12,889",
      dd9: "57,269",
      dd10: "11,893",
      dd11: "12,404",
      dd12: "10,077",
      dd13: "10,978",
      dd14: "11,917",
      dd15: "64,936",
      dd16: "33,866",
      dd17: "5,429",
      dd18: "220,003",
    },
    {
      drawNumber: "251",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=251'>251</a>",
      drawDate: "2023-06-27",
      drawDateFull: "June 27, 2023",
      drawName: "No Program Specified",
      drawSize: "4,300",
      drawCRS: "486",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=251'>Invitations to apply for permanent residence under the Express Entry system #251</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=251'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 27, 2023 15:37:46 UTC",
      drawCutOff: "June 21, 2023 00:57:04 UTC",
      drawDistributionAsOn: "June 27, 2023",
      dd1: "1,254",
      dd2: "1,258",
      dd3: "55,991",
      dd4: "1,040",
      dd5: "4,856",
      dd6: "20,980",
      dd7: "16,226",
      dd8: "12,889",
      dd9: "57,269",
      dd10: "11,893",
      dd11: "12,404",
      dd12: "10,077",
      dd13: "10,978",
      dd14: "11,917",
      dd15: "64,936",
      dd16: "33,866",
      dd17: "5,429",
      dd18: "220,003",
    },
    {
      drawNumber: "250",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=250'>250</a>",
      drawDate: "2023-06-08",
      drawDateFull: "June 8, 2023",
      drawName: "No Program Specified",
      drawSize: "4,800",
      drawCRS: "486",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=250'>Invitations to apply for permanent residence under the Express Entry system #250</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=250'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 08, 2023 12:08:58 UTC",
      drawCutOff: "May 19, 2023 14:04:00 UTC",
      drawDistributionAsOn: "June 8, 2023",
      dd1: "768",
      dd2: "1,092",
      dd3: "56,653",
      dd4: "1,096",
      dd5: "4,925",
      dd6: "21,083",
      dd7: "16,472",
      dd8: "13,077",
      dd9: "58,274",
      dd10: "12,082",
      dd11: "12,585",
      dd12: "10,371",
      dd13: "11,202",
      dd14: "12,034",
      dd15: "65,791",
      dd16: "34,208",
      dd17: "5,380",
      dd18: "222,166",
    },
    {
      drawNumber: "249",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=249'>249</a>",
      drawDate: "2023-05-24",
      drawDateFull: "May 24, 2023",
      drawName: "No Program Specified",
      drawSize: "4,800",
      drawCRS: "488",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=249'>Invitations to apply for permanent residence under the Express Entry system #249</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=249'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 24, 2023 14:23:06 UTC",
      drawCutOff: "December 26, 2022 01:12:48 UTC",
      drawDistributionAsOn: "May 24, 2023",
      dd1: "642",
      dd2: "1,896",
      dd3: "57,405",
      dd4: "1,814",
      dd5: "4,225",
      dd6: "21,351",
      dd7: "16,753",
      dd8: "13,262",
      dd9: "59,015",
      dd10: "12,257",
      dd11: "12,803",
      dd12: "10,397",
      dd13: "11,400",
      dd14: "12,158",
      dd15: "67,331",
      dd16: "35,098",
      dd17: "5,455",
      dd18: "226,842",
    },
    {
      drawNumber: "248",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=248'>248</a>",
      drawDate: "2023-05-10",
      drawDateFull: "May 10, 2023",
      drawName: "Provincial Nominee Program",
      drawSize: "589",
      drawCRS: "691",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=248'>Invitations to apply for permanent residence under the Express Entry system #248</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=248'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 10, 2023 at 14:06:47 UTC",
      drawCutOff: "March 08, 2023 at 16:42:17 UTC",
      drawDistributionAsOn: "May 10, 2023",
      dd1: "535",
      dd2: "992",
      dd3: "55,439",
      dd4: "1,094",
      dd5: "3,050",
      dd6: "21,189",
      dd7: "16,740",
      dd8: "13,366",
      dd9: "59,827",
      dd10: "12,443",
      dd11: "12,921",
      dd12: "10,578",
      dd13: "11,527",
      dd14: "12,358",
      dd15: "67,883",
      dd16: "35,242",
      dd17: "5,470",
      dd18: "225,388",
    },
    {
      drawNumber: "247",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=247'>247</a>",
      drawDate: "2023-04-26",
      drawDateFull: "April 26, 2023",
      drawName: "No Program Specified",
      drawSize: "3,500",
      drawCRS: "483",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=247'>Invitations to apply for permanent residence under the Express Entry system #247</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=247'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 26,2023 18:10:04 UTC",
      drawCutOff: "April 22, 2023 19:46:58 UTC",
      drawDistributionAsOn: "April 26, 2023",
      dd1: "604",
      dd2: "884",
      dd3: "55,867",
      dd4: "680",
      dd5: "3,001",
      dd6: "21,544",
      dd7: "17,111",
      dd8: "13,351",
      dd9: "61,108",
      dd10: "12,691",
      dd11: "13,187",
      dd12: "10,674",
      dd13: "11,846",
      dd14: "12,710",
      dd15: "69,709",
      dd16: "36,432",
      dd17: "5,585",
      dd18: "230,189",
    },
    {
      drawNumber: "246",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=246'>246</a>",
      drawDate: "2023-04-12",
      drawDateFull: "April 12, 2023",
      drawName: "No Program Specified",
      drawSize: "3,500",
      drawCRS: "486",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=246'>Invitations to apply for permanent residence under the Express Entry system #246</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=246'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 12, 2023 14:12:15 UTC",
      drawCutOff: "July 19, 2022 11:05:24 UTC",
      drawDistributionAsOn: "April 12, 2023",
      dd1: "501",
      dd2: "1,180",
      dd3: "56,092",
      dd4: "1,284",
      dd5: "2,871",
      dd6: "21,344",
      dd7: "17,139",
      dd8: "13,454",
      dd9: "61,379",
      dd10: "12,772",
      dd11: "13,228",
      dd12: "10,766",
      dd13: "11,864",
      dd14: "12,749",
      dd15: "69,975",
      dd16: "36,419",
      dd17: "5,573",
      dd18: "231,119",
    },
    {
      drawNumber: "245",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=245'>245</a>",
      drawDate: "2023-03-29",
      drawDateFull: "March 29, 2023",
      drawName: "No Program Specified",
      drawSize: "7,000",
      drawCRS: "481",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=245'>Invitations to apply for permanent residence under the Express Entry system #245</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=245'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 29, 2023 13:44:01 UTC",
      drawCutOff: "February 14, 2023 07:35:00 UTC",
      drawDistributionAsOn: "March 29, 2023",
      dd1: "261",
      dd2: "468",
      dd3: "58,698",
      dd4: "374",
      dd5: "6,859",
      dd6: "21,051",
      dd7: "16,996",
      dd8: "13,418",
      dd9: "61,381",
      dd10: "12,752",
      dd11: "13,291",
      dd12: "10,709",
      dd13: "11,917",
      dd14: "12,712",
      dd15: "71,304",
      dd16: "37,124",
      dd17: "5,711",
      dd18: "234,947",
    },
    {
      drawNumber: "244",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=244'>244</a>",
      drawDate: "2023-03-23",
      drawDateFull: "March 23, 2023",
      drawName: "No Program Specified",
      drawSize: "7,000",
      drawCRS: "484",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=244'>Invitations to apply for permanent residence under the Express Entry system #244</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=244'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 23,2023 12:27:59 UTC",
      drawCutOff: "May 19,2022 06:48:41 UTC",
      drawDistributionAsOn: "March 22, 2023",
      dd1: "310",
      dd2: "534",
      dd3: "63,666",
      dd4: "444",
      dd5: "12,001",
      dd6: "20,955",
      dd7: "16,863",
      dd8: "13,403",
      dd9: "61,323",
      dd10: "12,720",
      dd11: "13,300",
      dd12: "10,696",
      dd13: "11,925",
      dd14: "12,682",
      dd15: "71,424",
      dd16: "37,208",
      dd17: "5,681",
      dd18: "240,146",
    },
    {
      drawNumber: "243",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=243'>243</a>",
      drawDate: "2023-03-15",
      drawDateFull: "March 15, 2023",
      drawName: "No Program Specified",
      drawSize: "7,000",
      drawCRS: "490",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=243'>Invitations to apply for permanent residence under the Express Entry system #243</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=243'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 15, 2023 17:08:16 UTC",
      drawCutOff: "February 16, 2023 10:33:06 UTC",
      drawDistributionAsOn: "March 15, 2023",
      dd1: "784",
      dd2: "3,216",
      dd3: "65,826",
      dd4: "2,603",
      dd5: "12,183",
      dd6: "20,833",
      dd7: "16,836",
      dd8: "13,371",
      dd9: "61,240",
      dd10: "12,672",
      dd11: "13,334",
      dd12: "10,697",
      dd13: "11,833",
      dd14: "12,704",
      dd15: "71,292",
      dd16: "37,113",
      dd17: "5,650",
      dd18: "245,121",
    },
    {
      drawNumber: "242",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=242'>242</a>",
      drawDate: "2023-03-01",
      drawDateFull: "March 1, 2023",
      drawName: "Provincial Nominee Program",
      drawSize: "667",
      drawCRS: "748",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=242'>Invitations to apply for permanent residence under the Express Entry system #242</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=242'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "March 01, 2023, at 17:24:39 UTC",
      drawCutOff: "December 12, 2022, at 10:48:12 UTC",
      drawDistributionAsOn: "March 1, 2023",
      dd1: "606",
      dd2: "2,129",
      dd3: "65,187",
      dd4: "1,756",
      dd5: "12,126",
      dd6: "20,813",
      dd7: "17,042",
      dd8: "13,450",
      dd9: "61,086",
      dd10: "12,679",
      dd11: "13,300",
      dd12: "10,680",
      dd13: "11,785",
      dd14: "12,642",
      dd15: "71,529",
      dd16: "37,131",
      dd17: "5,706",
      dd18: "243,374",
    },
    {
      drawNumber: "241",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=241'>241</a>",
      drawDate: "2023-02-15",
      drawDateFull: "February 15, 2023",
      drawName: "Provincial Nominee Program",
      drawSize: "699",
      drawCRS: "791",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=241'>Invitations to apply for permanent residence under the Express Entry system #241</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=241'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "February 15, 2023, at 16:13:27 UTC",
      drawCutOff: "February 08, 2023, at 18:09:01 UTC",
      drawDistributionAsOn: "February 15, 2023",
      dd1: "725",
      dd2: "1,415",
      dd3: "64,315",
      dd4: "1,226",
      dd5: "11,895",
      dd6: "20,701",
      dd7: "17,058",
      dd8: "13,435",
      dd9: "60,559",
      dd10: "12,610",
      dd11: "13,230",
      dd12: "10,529",
      dd13: "11,680",
      dd14: "12,510",
      dd15: "70,932",
      dd16: "36,722",
      dd17: "5,596",
      dd18: "240,264",
    },
    {
      drawNumber: "240",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=240'>240</a>",
      drawDate: "2023-02-02",
      drawDateFull: "February 2, 2023",
      drawName: "Federal Skilled Worker",
      drawSize: "3,300",
      drawCRS: "489",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=240'>Invitations to apply for permanent residence under the Express Entry system #240</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=240'>See full text of Ministerial Instruction</a>",
      drawText2: "Federal Skilled Worker",
      drawDateTime: "February 02, 2023, at 15:12:01 UTC",
      drawCutOff: "March 29, 2022, at 16:09:00 UTC",
      drawDistributionAsOn: "February 1, 2023",
      dd1: "937",
      dd2: "1,553",
      dd3: "64,382",
      dd4: "1,353",
      dd5: "12,360",
      dd6: "20,467",
      dd7: "16,991",
      dd8: "13,211",
      dd9: "59,921",
      dd10: "12,481",
      dd11: "13,086",
      dd12: "10,289",
      dd13: "11,548",
      dd14: "12,517",
      dd15: "70,484",
      dd16: "36,636",
      dd17: "5,502",
      dd18: "239,415",
    },
    {
      drawNumber: "239",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=239'>239</a>",
      drawDate: "2023-02-01",
      drawDateFull: "February 1, 2023",
      drawName: "Provincial Nominee Program",
      drawSize: "893",
      drawCRS: "733",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=239'>Invitations to apply for permanent residence under the Express Entry system #239</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=239'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "February 01, 2023, at 16:05:57 UTC",
      drawCutOff: "December 09, 2022, at 10:49:57 UTC",
      drawDistributionAsOn: "February 1, 2023",
      dd1: "937",
      dd2: "1,553",
      dd3: "64,382",
      dd4: "1,353",
      dd5: "12,360",
      dd6: "20,467",
      dd7: "16,991",
      dd8: "13,211",
      dd9: "59,921",
      dd10: "12,481",
      dd11: "13,086",
      dd12: "10,289",
      dd13: "11,548",
      dd14: "12,517",
      dd15: "70,484",
      dd16: "36,636",
      dd17: "5,502",
      dd18: "239,415",
    },
    {
      drawNumber: "238",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=238'>238</a>",
      drawDate: "2023-01-18",
      drawDateFull: "January 18, 2023",
      drawName: "No Program Specified",
      drawSize: "5,500",
      drawCRS: "490",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=238'>Invitations to apply for permanent residence under the Express Entry system #238</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=238'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 18, 2023 16:13:57 UTC",
      drawCutOff: "November 24, 2022 21:14:59 UTC",
      drawDistributionAsOn: "January 18, 2023",
      dd1: "614",
      dd2: "1,918",
      dd3: "65,296",
      dd4: "3,384",
      dd5: "11,848",
      dd6: "20,106",
      dd7: "16,854",
      dd8: "13,104",
      dd9: "59,491",
      dd10: "12,416",
      dd11: "13,010",
      dd12: "10,159",
      dd13: "11,444",
      dd14: "12,462",
      dd15: "70,421",
      dd16: "36,754",
      dd17: "5,519",
      dd18: "240,013",
    },
    {
      drawNumber: "237",
      drawNumberURL:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=237'>237</a>",
      drawDate: "2023-01-11",
      drawDateFull: "January 11, 2023",
      drawName: "No Program Specified",
      drawSize: "5,500",
      drawCRS: "507",
      mitext:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=237'>Invitations to apply for permanent residence under the Express Entry system #237</a>",
      DrawText1:
        "<a href='/content/canadasite/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=237'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 11, 2023 16:14:19 UTC",
      drawCutOff: "December 06, 2022 11:00:07 UTC",
      drawDistributionAsOn: "January 11, 2023",
      dd1: "2,995",
      dd2: "3,897",
      dd3: "64,866",
      dd4: "3,141",
      dd5: "11,783",
      dd6: "20,030",
      dd7: "16,802",
      dd8: "13,110",
      dd9: "59,277",
      dd10: "12,354",
      dd11: "12,928",
      dd12: "10,152",
      dd13: "11,394",
      dd14: "12,449",
      dd15: "70,204",
      dd16: "36,547",
      dd17: "5,469",
      dd18: "243,255",
    },
    {
      drawNumber: "236",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=236'>236</a>",
      drawDate: "2022-11-23",
      drawDateFull: "November 23, 2022",
      drawName: "No Program Specified",
      drawSize: "4,750",
      drawCRS: "491",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=236'>Invitations to apply for permanent residence under the Express Entry system #236</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=236'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 23, 2022 15:42:46 UTC",
      drawCutOff: "October 13, 2022 11:22:17 UTC",
      drawDistributionAsOn: "November 22, 2022",
      dd1: "876",
      dd2: "1,227",
      dd3: "62,400",
      dd4: "3,031",
      dd5: "10,517",
      dd6: "19,494",
      dd7: "16,523",
      dd8: "12,835",
      dd9: "58,357",
      dd10: "12,228",
      dd11: "12,823",
      dd12: "9,896",
      dd13: "11,209",
      dd14: "12,201",
      dd15: "68,489",
      dd16: "35,614",
      dd17: "5,252",
      dd18: "232,215",
    },
    {
      drawNumber: "235",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=235'>235</a>",
      drawDate: "2022-11-09",
      drawDateFull: "November 9, 2022",
      drawName: "No Program Specified",
      drawSize: "4,750",
      drawCRS: "494",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=235'>Invitations to apply for permanent residence under the Express Entry system #235</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=235'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 09, 2022 14:21:26 UTC",
      drawCutOff: "June 06, 2022 20:48:23 UTC",
      drawDistributionAsOn: "November 7, 2022",
      dd1: "958",
      dd2: "1,206",
      dd3: "63,525",
      dd4: "4,366",
      dd5: "10,270",
      dd6: "19,388",
      dd7: "16,633",
      dd8: "12,868",
      dd9: "58,522",
      dd10: "12,320",
      dd11: "12,856",
      dd12: "9,871",
      dd13: "11,194",
      dd14: "12,281",
      dd15: "68,711",
      dd16: "35,610",
      dd17: "5,087",
      dd18: "233,619",
    },
    {
      drawNumber: "234",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=234'>234</a>",
      drawDate: "2022-10-26",
      drawDateFull: "October 26, 2022",
      drawName: "No Program Specified",
      drawSize: "4,750",
      drawCRS: "496",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=234'>Invitations to apply for permanent residence under the Express Entry system #234</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=234'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 26, 2022 13:05:36 UTC",
      drawCutOff: "May 09, 2022 09:04:20 UTC",
      drawDistributionAsOn: "October 24, 2022",
      dd1: "762",
      dd2: "937",
      dd3: "64,829",
      dd4: "6,226",
      dd5: "9,956",
      dd6: "19,241",
      dd7: "16,539",
      dd8: "12,867",
      dd9: "58,249",
      dd10: "12,268",
      dd11: "12,775",
      dd12: "9,789",
      dd13: "11,223",
      dd14: "12,194",
      dd15: "69,192",
      dd16: "35,904",
      dd17: "5,108",
      dd18: "234,981",
    },
    {
      drawNumber: "233",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=233'>233</a>",
      drawDate: "2022-10-12",
      drawDateFull: "October 12, 2022",
      drawName: "No Program Specified",
      drawSize: "4,250",
      drawCRS: "500",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=233'>Invitations to apply for permanent residence under the Express Entry system #233</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=233'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 12, 2022 14:13:11 UTC",
      drawCutOff: "October 10, 2022 17:10:53 UTC",
      drawDistributionAsOn: "October 10, 2022",
      dd1: "601",
      dd2: "2,785",
      dd3: "64,536",
      dd4: "6,384",
      dd5: "9,784",
      dd6: "19,163",
      dd7: "16,396",
      dd8: "12,809",
      dd9: "57,962",
      dd10: "12,236",
      dd11: "12,629",
      dd12: "9,777",
      dd13: "11,204",
      dd14: "12,116",
      dd15: "68,680",
      dd16: "35,501",
      dd17: "5,055",
      dd18: "235,120",
    },
    {
      drawNumber: "232",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=232'>232</a>",
      drawDate: "2022-09-28",
      drawDateFull: "September 28, 2022",
      drawName: "No Program Specified",
      drawSize: "3,750",
      drawCRS: "504",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=232'>Invitations to apply for permanent residence under the Express Entry system #232</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=232'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 28, 2022 at 13:52:35 UTC",
      drawCutOff: "May 02, 2022 at 17:17:32 UTC",
      drawDistributionAsOn: "September 26, 2022",
      dd1: "479",
      dd2: "4,411",
      dd3: "64,097",
      dd4: "5,985",
      dd5: "9,572",
      dd6: "19,151",
      dd7: "16,562",
      dd8: "12,827",
      dd9: "58,143",
      dd10: "12,259",
      dd11: "12,673",
      dd12: "9,802",
      dd13: "11,209",
      dd14: "12,200",
      dd15: "69,175",
      dd16: "36,006",
      dd17: "5,112",
      dd18: "237,423",
    },
    {
      drawNumber: "231",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=231'>231</a>",
      drawDate: "2022-09-14",
      drawDateFull: "September 14, 2022",
      drawName: "No Program Specified",
      drawSize: "3,250",
      drawCRS: "510",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=231'>Invitations to apply for permanent residence under the Express Entry system #231</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=231'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 14, 2022 at 13:29:26 UTC",
      drawCutOff: "January 08, 2022 at 10:24:52 UTC",
      drawDistributionAsOn: "September 12, 2022",
      dd1: "408",
      dd2: "6,228",
      dd3: "63,860",
      dd4: "5,845",
      dd5: "9,505",
      dd6: "19,156",
      dd7: "16,541",
      dd8: "12,813",
      dd9: "58,019",
      dd10: "12,245",
      dd11: "12,635",
      dd12: "9,767",
      dd13: "11,186",
      dd14: "12,186",
      dd15: "68,857",
      dd16: "35,833",
      dd17: "5,068",
      dd18: "238,273",
    },
    {
      drawNumber: "230",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=230'>230</a>",
      drawDate: "2022-08-31",
      drawDateFull: "August 31, 2022",
      drawName: "No Program Specified",
      drawSize: "2,750",
      drawCRS: "516",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=230'>Invitations to apply for permanent residence under the Express Entry system #230</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=230'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 31, 2022 at 13:55:23 UTC",
      drawCutOff: "April 16, 2022 at 18:24:41 UTC",
      drawDistributionAsOn: "August 29, 2022",
      dd1: "466",
      dd2: "7,224",
      dd3: "63,270",
      dd4: "5,554",
      dd5: "9,242",
      dd6: "19,033",
      dd7: "16,476",
      dd8: "12,965",
      dd9: "58,141",
      dd10: "12,287",
      dd11: "12,758",
      dd12: "9,796",
      dd13: "11,105",
      dd14: "12,195",
      dd15: "68,974",
      dd16: "36,001",
      dd17: "5,120",
      dd18: "239,196",
    },
    {
      drawNumber: "229",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=229'>229</a>",
      drawDate: "2022-08-17",
      drawDateFull: "August 17, 2022",
      drawName: "No Program Specified",
      drawSize: "2,250",
      drawCRS: "525",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=229'>Invitations to apply for permanent residence under the Express Entry system #229</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=229'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 17, 2022 at 13:43:47 UTC",
      drawCutOff: "December 28, 2021 at 11:03:15 UTC",
      drawDistributionAsOn: "August 15, 2022",
      dd1: "538",
      dd2: "8,221",
      dd3: "62,753",
      dd4: "5,435",
      dd5: "9,129",
      dd6: "18,831",
      dd7: "16,465",
      dd8: "12,893",
      dd9: "58,113",
      dd10: "12,200",
      dd11: "12,721",
      dd12: "9,801",
      dd13: "11,138",
      dd14: "12,253",
      dd15: "68,440",
      dd16: "35,745",
      dd17: "5,137",
      dd18: "238,947",
    },
    {
      drawNumber: "228",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=228'>228</a>",
      drawDate: "2022-08-03",
      drawDateFull: "August 3, 2022",
      drawName: "No Program Specified",
      drawSize: "2,000",
      drawCRS: "533",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=228'>Invitations to apply for permanent residence under the Express Entry system #228</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=228'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 03, 2022 at 15:16:24 UTC",
      drawCutOff: "January 06, 2022 at 14:29:50 UTC",
      drawDistributionAsOn: "August 2, 2022",
      dd1: "640",
      dd2: "8,975",
      dd3: "62,330",
      dd4: "5,343",
      dd5: "9,044",
      dd6: "18,747",
      dd7: "16,413",
      dd8: "12,783",
      dd9: "57,987",
      dd10: "12,101",
      dd11: "12,705",
      dd12: "9,747",
      dd13: "11,117",
      dd14: "12,317",
      dd15: "68,325",
      dd16: "35,522",
      dd17: "5,145",
      dd18: "238,924",
    },
    {
      drawNumber: "227",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=227'>227</a>",
      drawDate: "2022-07-20",
      drawDateFull: "July 20, 2022",
      drawName: "No Program Specified",
      drawSize: "1,750",
      drawCRS: "542",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=227'>Invitations to apply for permanent residence under the Express Entry system #227</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=227'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 20, 2022 at 16:32:49 UTC",
      drawCutOff: "December 30, 2021 at 15:29:35 UTC",
      drawDistributionAsOn: "July 18, 2022",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "226",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=226'>226</a>",
      drawDate: "2022-07-06",
      drawDateFull: "July 6, 2022",
      drawName: "No Program Specified",
      drawSize: "1,500",
      drawCRS: "557",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=226'>Invitations to apply for permanent residence under the Express Entry system #226</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=226'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 6, 2022 at 14:34:34 UTC",
      drawCutOff: "November 13, 2021 at 02:20:46 UTC",
      drawDistributionAsOn: "July 11, 2022",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "225",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=225'>225</a>",
      drawDate: "2022-06-22",
      drawDateFull: "June 22, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "636",
      drawCRS: "752",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=225'>Invitations to apply for permanent residence under the Express Entry system #225</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=225'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "June 22, 2022 at 14:13:57 UTC",
      drawCutOff: "April 19, 2022 at 13:45:45 UTC",
      drawDistributionAsOn: "June 20, 2022",
      dd1: "664",
      dd2: "8,017",
      dd3: "55,917",
      dd4: "4,246",
      dd5: "7,845",
      dd6: "16,969",
      dd7: "15,123",
      dd8: "11,734",
      dd9: "53,094",
      dd10: "10,951",
      dd11: "11,621",
      dd12: "8,800",
      dd13: "10,325",
      dd14: "11,397",
      dd15: "64,478",
      dd16: "33,585",
      dd17: "4,919",
      dd18: "220,674",
    },
    {
      drawNumber: "224",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=224'>224</a>",
      drawDate: "2022-06-08",
      drawDateFull: "June 8, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "932",
      drawCRS: "796",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=224'>Invitations to apply for permanent residence under the Express Entry system #224</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=224'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "June 08, 2022 at 14:03:28 UTC",
      drawCutOff: "October 18, 2021 at 17:13:17 UTC",
      drawDistributionAsOn: "June 6, 2022",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "223",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=223'>223</a>",
      drawDate: "2022-05-25",
      drawDateFull: "May 25, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "590",
      drawCRS: "741",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=223'>Invitations to apply for permanent residence under the Express Entry system #223</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=223'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 25, 2022 at 13:21:23 UTC",
      drawCutOff: "February 02, 2022 at 12:29:53 UTC",
      drawDistributionAsOn: "May 23, 2022",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "222",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=222'>222</a>",
      drawDate: "2022-05-11",
      drawDateFull: "May 11, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "545",
      drawCRS: "753",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=222'>Invitations to apply for permanent residence under the Express Entry system #222</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=222'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 11, 2022 at 14:08:07 UTC",
      drawCutOff: "December 15, 2021 at 20:32:57 UTC",
      drawDistributionAsOn: "May 9, 2022",
      dd1: "635",
      dd2: "7,193",
      dd3: "52,684",
      dd4: "3,749",
      dd5: "7,237",
      dd6: "16,027",
      dd7: "14,466",
      dd8: "11,205",
      dd9: "50,811",
      dd10: "10,484",
      dd11: "11,030",
      dd12: "8,393",
      dd13: "9,945",
      dd14: "10,959",
      dd15: "62,341",
      dd16: "32,590",
      dd17: "4,839",
      dd18: "211,093",
    },
    {
      drawNumber: "221",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=221'>221</a>",
      drawDate: "2022-04-27",
      drawDateFull: "April 27, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "829",
      drawCRS: "772",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=221'>Invitations to apply for permanent residence under the Express Entry system #221</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=221'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "April 27, 2022 16:04:21 UTC",
      drawCutOff: "March 24, 2022 at 8:00:50 UTC",
      drawDistributionAsOn: "April 27, 2022",
      dd1: "747",
      dd2: "6,577",
      dd3: "50,330",
      dd4: "3,498",
      dd5: "6,826",
      dd6: "15,382",
      dd7: "13,851",
      dd8: "10,773",
      dd9: "49,092",
      dd10: "10,117",
      dd11: "10,685",
      dd12: "8,012",
      dd13: "9,615",
      dd14: "10,663",
      dd15: "60,456",
      dd16: "31,705",
      dd17: "4,866",
      dd18: "203,773",
    },
    {
      drawNumber: "220",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=220'>220</a>",
      drawDate: "2022-04-13",
      drawDateFull: "April 13, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "787",
      drawCRS: "782",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=220'>Invitations to apply for permanent residence under the Express Entry system #220</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=220'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "April 13, 2022 at 14:13:01 UTC",
      drawCutOff: "April 07, 2022 at 16:31:21 UTC",
      drawDistributionAsOn: "April 11, 2022",
      dd1: "810",
      dd2: "6,391",
      dd3: "49,555",
      dd4: "3,406",
      dd5: "6,677",
      dd6: "15,298",
      dd7: "13,584",
      dd8: "10,590",
      dd9: "48,609",
      dd10: "10,034",
      dd11: "10,576",
      dd12: "7,877",
      dd13: "9,555",
      dd14: "10,567",
      dd15: "59,500",
      dd16: "31,148",
      dd17: "4,911",
      dd18: "200,924",
    },
    {
      drawNumber: "219",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=219'>219</a>",
      drawDate: "2022-03-30",
      drawDateFull: "March 30, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "919",
      drawCRS: "785",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=219'>Invitations to apply for permanent residence under the Express Entry system #219</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=219'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "March 30, 2022 at 15:41:09 UTC",
      drawCutOff: "November 15, 2021 at 20:23:16 UTC",
      drawDistributionAsOn: "March 28, 2022",
      dd1: "810",
      dd2: "6,391",
      dd3: "49,555",
      dd4: "3,406",
      dd5: "6,677",
      dd6: "15,298",
      dd7: "13,584",
      dd8: "10,590",
      dd9: "48,609",
      dd10: "10,034",
      dd11: "10,576",
      dd12: "7,877",
      dd13: "9,555",
      dd14: "10,567",
      dd15: "59,500",
      dd16: "31,148",
      dd17: "4,911",
      dd18: "200,924",
    },
    {
      drawNumber: "218",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=218'>218</a>",
      drawDate: "2022-03-16",
      drawDateFull: "March 16, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "924",
      drawCRS: "754",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=218'>Invitations to apply for permanent residence under the Express Entry system #218</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=218'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "March 16, 2022 at 14:59:09 UTC",
      drawCutOff: "February 10, 2022 at 14:35:27 UTC",
      drawDistributionAsOn: "March 14, 2022",
      dd1: "948",
      dd2: "5,840",
      dd3: "48,027",
      dd4: "3,130",
      dd5: "6,357",
      dd6: "14,853",
      dd7: "13,333",
      dd8: "10,354",
      dd9: "47,569",
      dd10: "9,796",
      dd11: "10,293",
      dd12: "7,701",
      dd13: "9,319",
      dd14: "10,460",
      dd15: "58,456",
      dd16: "30,768",
      dd17: "5,084",
      dd18: "196,692",
    },
    {
      drawNumber: "217",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=217'>217</a>",
      drawDate: "2022-03-02",
      drawDateFull: "March 2, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "1,047",
      drawCRS: "761",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=217'>Invitations to apply for permanent residence under the Express Entry system #217</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=217'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "March 02, 2022 at 14:05:52 UTC",
      drawCutOff: "January 05, 2022 at 14:22:03 UTC",
      drawDistributionAsOn: "February 28, 2022",
      dd1: "873",
      dd2: "5,563",
      dd3: "47,599",
      dd4: "2,989",
      dd5: "6,310",
      dd6: "14,759",
      dd7: "13,218",
      dd8: "10,323",
      dd9: "47,276",
      dd10: "9,736",
      dd11: "10,207",
      dd12: "7,633",
      dd13: "9,240",
      dd14: "10,460",
      dd15: "58,253",
      dd16: "31,018",
      dd17: "5,313",
      dd18: "195,895",
    },
    {
      drawNumber: "216",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=216'>216</a>",
      drawDate: "2022-02-16",
      drawDateFull: "February 16, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "1,082",
      drawCRS: "710",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=216'>Invitations to apply for permanent residence under the Express Entry system #216</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=216'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "February 16, 2022 at 14:36:05 UTC",
      drawCutOff: "October 07, 2021 at 11:36:55 UTC",
      drawDistributionAsOn: "February 15, 2022",
      dd1: "1,099",
      dd2: "5,440",
      dd3: "47,721",
      dd4: "2,921",
      dd5: "6,289",
      dd6: "14,775",
      dd7: "13,273",
      dd8: "10,463",
      dd9: "47,509",
      dd10: "9,715",
      dd11: "10,229",
      dd12: "7,675",
      dd13: "9,292",
      dd14: "10,598",
      dd15: "59,011",
      dd16: "31,545",
      dd17: "5,736",
      dd18: "198,061",
    },
    {
      drawNumber: "215",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=215'>215</a>",
      drawDate: "2022-02-02",
      drawDateFull: "February 2, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "1,070",
      drawCRS: "674",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=215'>Invitations to apply for permanent residence under the Express Entry system #215</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=215'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "February 02 2022 at 14:16:27 UTC",
      drawCutOff: "February 18 2021 at 05:05:15 UTC",
      drawDistributionAsOn: "January 31, 2022",
      dd1: "983",
      dd2: "5,201",
      dd3: "47,441",
      dd4: "2,811",
      dd5: "6,205",
      dd6: "14,847",
      dd7: "13,132",
      dd8: "10,446",
      dd9: "47,428",
      dd10: "9,753",
      dd11: "10,165",
      dd12: "7,697",
      dd13: "9,199",
      dd14: "10,614",
      dd15: "59,039",
      dd16: "31,734",
      dd17: "5,834",
      dd18: "197,660",
    },
    {
      drawNumber: "214",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=214'>214</a>",
      drawDate: "2022-01-19",
      drawDateFull: "January 19, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "1,036",
      drawCRS: "745",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=214'>Invitations to apply for permanent residence under the Express Entry system #214</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=214'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "January 19, 2022 at 14:39:22 UTC",
      drawCutOff: "July 05, 2021 at 13:12:05 UTC",
      drawDistributionAsOn: "January 17, 2022",
      dd1: "899",
      dd2: "5,106",
      dd3: "47,202",
      dd4: "2,738",
      dd5: "6,192",
      dd6: "14,828",
      dd7: "13,010",
      dd8: "10,434",
      dd9: "47,283",
      dd10: "9,650",
      dd11: "10,225",
      dd12: "7,687",
      dd13: "9,139",
      dd14: "10,582",
      dd15: "58,692",
      dd16: "31,704",
      dd17: "5,799",
      dd18: "196,685",
    },
    {
      drawNumber: "213",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=213'>213</a>",
      drawDate: "2022-01-05",
      drawDateFull: "January 5, 2022",
      drawName: "Provincial Nominee Program",
      drawSize: "392",
      drawCRS: "808",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=213'>Invitations to apply for permanent residence under the Express Entry system #213</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=213'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "January 05, 2022 at 15:50:48 UTC",
      drawCutOff: "March 04, 2021 at 16:57:50 UTC",
      drawDistributionAsOn: "January 5, 2022",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "212",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=212'>212</a>",
      drawDate: "2021-12-22",
      drawDateFull: "December 22, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "746",
      drawCRS: "720",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=212'>Invitations to apply for permanent residence under the Express Entry system #212</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=212'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "December 22, 2021 at 16:27:24 UTC",
      drawCutOff: "November 24, 2021 at 16:56:42 UTC",
      drawDistributionAsOn: "December 22, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "211",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=211'>211</a>",
      drawDate: "2021-12-10",
      drawDateFull: "December 10, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "1,032",
      drawCRS: "698",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=211'>Invitations to apply for permanent residence under the Express Entry system #211</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=211'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "December 10, 2021 at 13:53:48 UTC",
      drawCutOff: "September 10, 2021 at 16:43:17 UTC",
      drawDistributionAsOn: "December 10, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "210",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=210'>210</a>",
      drawDate: "2021-11-24",
      drawDateFull: "November 24, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "613",
      drawCRS: "737",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=210'>Invitations to apply for permanent residence under the Express Entry system #210</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=210'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "November 24, 2021 at 14:01:44 UTC",
      drawCutOff: "June 22, 2021 at 20:19:59 PM UTC",
      drawDistributionAsOn: "November 24, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "209",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=209'>209</a>",
      drawDate: "2021-11-10",
      drawDateFull: "November 10, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "775",
      drawCRS: "685",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=209'>Invitations to apply for permanent residence under the Express Entry system #209</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=209'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "November 10, 2021 at 17:02:21 UTC",
      drawCutOff: "August 12, 2021 at 20:05:46 UTC",
      drawDistributionAsOn: "November 10, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "208",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=208'>208</a>",
      drawDate: "2021-10-27",
      drawDateFull: "October 27, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "681",
      drawCRS: "744",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=208'>Invitations to apply for permanent residence under the Express Entry system #208</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=208'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "October 27, 2021 at 13:32:26 UTC",
      drawCutOff: "October 7 at 16:38:21 UTC",
      drawDistributionAsOn: "October 27, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "207",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=207'>207</a>",
      drawDate: "2021-10-13",
      drawDateFull: "October 13, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "681",
      drawCRS: "720",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=207'>Invitations to apply for permanent residence under the Express Entry system #207</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=207'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "October 13, 2021 at 13:13:38 UTC",
      drawCutOff: "March 16, 2021 at 07:53:28 UTC",
      drawDistributionAsOn: "October 13, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "206",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=206'>206</a>",
      drawDate: "2021-09-29",
      drawDateFull: "September 29, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "761",
      drawCRS: "742",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=206'>Invitations to apply for permanent residence under the Express Entry system #206</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=206'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "September 29, 2021 at 13:17:49 UTC",
      drawCutOff: "July 29, 2021 at 09:45:29 UTC",
      drawDistributionAsOn: "September 29, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "205",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=205'>205</a>",
      drawDate: "2021-09-15",
      drawDateFull: "September 15, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "521",
      drawCRS: "732",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=205'>Invitations to apply for permanent residence under the Express Entry system #205</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=205'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "September 15, 2021 at 14:59:17 UTC",
      drawCutOff: "February 24, 2021 at 10:46:54 UTC",
      drawDistributionAsOn: "September 15, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "204",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=204'>204</a>",
      drawDate: "2021-09-14",
      drawDateFull: "September 14, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "2,000",
      drawCRS: "462",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=204'>Invitations to apply for permanent residence under the Express Entry system #204</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=204'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "September 14, 2021 at 13:06:02 UTC",
      drawCutOff: "September 11, 2021 at 10:55:43 UTC",
      drawDistributionAsOn: "September 14, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "203",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=203'>203</a>",
      drawDate: "2021-09-01",
      drawDateFull: "September 1, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "635",
      drawCRS: "764",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=203'>Invitations to apply for permanent residence under the Express Entry system #203</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=203'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "September 01, 2021 at 13:34:47 UTC",
      drawCutOff: "April 12, 2021 at 12:21:45 UTC",
      drawDistributionAsOn: "September 1, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "202",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=202'>202</a>",
      drawDate: "2021-08-19",
      drawDateFull: "August 19, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "3,000",
      drawCRS: "403",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=202'>Invitations to apply for permanent residence under the Express Entry system #202</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=202'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "August 19, 2021 at 13:25:22 UTC",
      drawCutOff: "August 01, 2021 at 10:13:57 UTC",
      drawDistributionAsOn: "August 19, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "201",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=201'>201</a>",
      drawDate: "2021-08-18",
      drawDateFull: "August 18, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "463",
      drawCRS: "751",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=201'>Invitations to apply for permanent residence under the Express Entry system #201</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=201'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "August 18, 2021 at 14:10:39 UTC",
      drawCutOff: "April 13, 2021 at 16:29:29 UTC",
      drawDistributionAsOn: "August 18, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "200",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=200'>200</a>",
      drawDate: "2021-08-05",
      drawDateFull: "August 5, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "3,000",
      drawCRS: "404",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=200'>Invitations to apply for permanent residence under the Express Entry system #200</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=200'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "August 5, 2021 at 13:11:37 UTC",
      drawCutOff: "August 3, 2021 at 05:39:26 UTC",
      drawDistributionAsOn: "August 5, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "199",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=199'>199</a>",
      drawDate: "2021-08-04",
      drawDateFull: "August 4, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "512",
      drawCRS: "760",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=199'>Invitations to apply for permanent residence under the Express Entry system #199</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=199'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "August 4, 2021 at 13:33:36 UTC",
      drawCutOff: "March 5, 2021 at 22:38:31 UTC",
      drawDistributionAsOn: "August 4, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "198",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=198'>198</a>",
      drawDate: "2021-07-22",
      drawDateFull: "July 22, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "4,500",
      drawCRS: "357",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=198'>Invitations to apply for permanent residence under the Express Entry system #198</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=198'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "July 22, 2021 at 14:02:32 UTC",
      drawCutOff: "February 14, 2021 at 09:04:15 UTC",
      drawDistributionAsOn: "July 22, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "197",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=197'>197</a>",
      drawDate: "2021-07-21",
      drawDateFull: "July 21, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "462",
      drawCRS: "734",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=197'>Invitations to apply for permanent residence under the Express Entry system #197</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=197'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "July 21, 2021 at 13:01:45 UTC",
      drawCutOff: "April 11, 2021 at 10:56:32 UTC",
      drawDistributionAsOn: "July 21, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "196",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=196'>196</a>",
      drawDate: "2021-07-08",
      drawDateFull: "July 8, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "4,500",
      drawCRS: "369",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=196'>Invitations to apply for permanent residence under the Express Entry system #196</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=196'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "July 08, 2021 at 14:02:45 UTC",
      drawCutOff: "June 10, 2021 at 22:46:37 UTC",
      drawDistributionAsOn: "July 8, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "195",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=195'>195</a>",
      drawDate: "2021-07-07",
      drawDateFull: "July 7, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "627",
      drawCRS: "760",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=195'>Invitations to apply for permanent residence under the Express Entry system #195</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=195'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "July 7, 2021 at 13:17:37 UTC",
      drawCutOff: "June 14, 2021 at 07:44:05 UTC",
      drawDistributionAsOn: "July 7, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "194",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=194'>194</a>",
      drawDate: "2021-06-24",
      drawDateFull: "June 24, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "6,000",
      drawCRS: "357",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=194'>Invitations to apply for permanent residence under the Express Entry system #194</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=194'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "June 24, 2021 at 14:39:59 UTC",
      drawCutOff: "February 17, 2021 at 10:15:50 UTC",
      drawDistributionAsOn: "June 24, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "193",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=193'>193</a>",
      drawDate: "2021-06-23",
      drawDateFull: "June 23, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "1,002",
      drawCRS: "742",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=193'>Invitations to apply for permanent residence under the Express Entry system #193</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=193'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "June 23, 2021 at 15:41:38 UTC",
      drawCutOff: "February 18, 2021 at 04:04:56 UTC",
      drawDistributionAsOn: "June 23, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "192",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=192'>192</a>",
      drawDate: "2021-06-10",
      drawDateFull: "June 10, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "6,000",
      drawCRS: "368",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=192'>Invitations to apply for permanent residence under the Express Entry system #192</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=192'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "June 10, 2021 at 12:29:11 UTC",
      drawCutOff: "April 28, 2021 at 05:45:14 UTC",
      drawDistributionAsOn: "June 10, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "191",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=191'>191</a>",
      drawDate: "2021-06-09",
      drawDateFull: "June 9, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "940",
      drawCRS: "711",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=191'>Invitations to apply for permanent residence under the Express Entry system #191</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=191'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "June 09, 2021 at 13:16:30 UTC",
      drawCutOff: "February 16, 2021 at 09:06:30 UTC",
      drawDistributionAsOn: "June 9, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "190",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=190'>190</a>",
      drawDate: "2021-05-31",
      drawDateFull: "May 31, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "5,956",
      drawCRS: "380",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=190'>Invitations to apply for permanent residence under the Express Entry system #190</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=190'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "May 31, 2021 at 13:19:23 UTC",
      drawCutOff: "May 28, 2021 at 14:45:22 UTC",
      drawDistributionAsOn: "May 31, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "189",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=189'>189</a>",
      drawDate: "2021-05-26",
      drawDateFull: "May 26, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "500",
      drawCRS: "713",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=189'>Invitations to apply for permanent residence under the Express Entry system #189</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=189'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 26, 2021 at 10:41:44 UTC",
      drawCutOff: "April 06, 2021 at 09:50:14 UTC",
      drawDistributionAsOn: "May 26, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "188",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=188'>188</a>",
      drawDate: "2021-05-20",
      drawDateFull: "May 20, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "1,842",
      drawCRS: "397",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=188'>Invitations to apply for permanent residence under the Express Entry system #188</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=188'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "May 20, 2021 at 10:10:54 UTC",
      drawCutOff: "April 24, 2021 at 12:09:24 UTC",
      drawDistributionAsOn: "May 20, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "187",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=187'>187</a>",
      drawDate: "2021-05-13",
      drawDateFull: "May 13, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "4,147",
      drawCRS: "401",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=187'>Invitations to apply for permanent residence under the Express Entry system #187</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=187'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "May 13, 2021 at 16:50:04 UTC",
      drawCutOff: "April 29, 2021 at 11:48:14 UTC",
      drawDistributionAsOn: "May 13, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "186",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=186'>186</a>",
      drawDate: "2021-05-12",
      drawDateFull: "May 12, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "557",
      drawCRS: "752",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=186'>Invitations to apply for permanent residence under the Express Entry system #186</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=186'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 12, 2021 at 10:28:53 UTC",
      drawCutOff: "April 29, 2021 at 10:18:12 UTC",
      drawDistributionAsOn: "May 12, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "185",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=185'>185</a>",
      drawDate: "2021-04-29",
      drawDateFull: "April 29, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "6,000",
      drawCRS: "400",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=185'>Invitations to apply for permanent residence under the Express Entry system #185</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=185'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "April 29, 2021 at 10:34:05 UTC",
      drawCutOff: "April 24, 2021 at 15:24:49 UTC",
      drawDistributionAsOn: "April 29, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "184",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=184'>184</a>",
      drawDate: "2021-04-28",
      drawDateFull: "April 28, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "381",
      drawCRS: "717",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=184'>Invitations to apply for permanent residence under the Express Entry system #184</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=184'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "April 28, 2021 at 11:00:02 UTC",
      drawCutOff: "March 16, 2021 at 21:20:50 UTC",
      drawDistributionAsOn: "April 28, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "183",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=183'>183</a>",
      drawDate: "2021-04-16",
      drawDateFull: "April 16, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "6,000",
      drawCRS: "417",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=183'>Invitations to apply for permanent residence under the Express Entry system #183</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=183'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "April 16, 2021 at 21:35:52 UTC",
      drawCutOff: "March 01, 2021 at 19:53:46 UTC",
      drawDistributionAsOn: "April 16, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "182",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=182'>182</a>",
      drawDate: "2021-04-14",
      drawDateFull: "April 14, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "266",
      drawCRS: "753",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=182'>Invitations to apply for permanent residence under the Express Entry system #182</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=182'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "April 14, 2021 at 11:01:51 UTC",
      drawCutOff: "March 01, 2021 at 15:22:18 UTC",
      drawDistributionAsOn: "April 14, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "181",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=181'>181</a>",
      drawDate: "2021-04-01",
      drawDateFull: "April 1, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "5,000",
      drawCRS: "432",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=181'>Invitations to apply for permanent residence under the Express Entry system #181</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=181'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "April 1, 2021 at 11:04:53 UTC",
      drawCutOff: "February 16, 2021 at 09:51:22 UTC",
      drawDistributionAsOn: "April 1, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "180",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=180'>180</a>",
      drawDate: "2021-03-31",
      drawDateFull: "March 31, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "284",
      drawCRS: "778",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=180'>Invitations to apply for permanent residence under the Express Entry system #180</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=180'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "March 31, 2021 at 11:21:22 UTC",
      drawCutOff: "March 16, 2021 at 16:09:32 UTC",
      drawDistributionAsOn: "March 31, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "179",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=179'>179</a>",
      drawDate: "2021-03-18",
      drawDateFull: "March 18, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "5,000",
      drawCRS: "449",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=179'>Invitations to apply for permanent residence under the Express Entry system #179</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=179'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "March 18, 2021 at 11:30:56 UTC",
      drawCutOff: "February 19, 2021 at 15:59:57 UTC",
      drawDistributionAsOn: "March 18, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "178",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=178'>178</a>",
      drawDate: "2021-03-17",
      drawDateFull: "March 17, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "183",
      drawCRS: "682",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=178'>Invitations to apply for permanent residence under the Express Entry system #178</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=178'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "March 17, 2021 at 11:49:48 UTC",
      drawCutOff: "March 04, 2021 at 16:56:20 UTC",
      drawDistributionAsOn: "March 17, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "177",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=177'>177</a>",
      drawDate: "2021-03-08",
      drawDateFull: "March 8, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "671",
      drawCRS: "739",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=177'>Invitations to apply for permanent residence under the Express Entry system #177</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=177'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "March 08, 2021 at 13:53:21 UTC",
      drawCutOff: "February 16, 2021 at 19:10:25 UTC",
      drawDistributionAsOn: "March 8, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "176",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=176'>176</a>",
      drawDate: "2021-02-13",
      drawDateFull: "February 13, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "27,332",
      drawCRS: "75",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=176'>Invitations to apply for permanent residence under the Express Entry system #176</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=176'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "February 13, 2021 at 11:56:56 UTC",
      drawCutOff: "September 12, 2020 at 15:31:40 UTC",
      drawDistributionAsOn: "February 13, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "175",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=175'>175</a>",
      drawDate: "2021-02-10",
      drawDateFull: "February 10, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "654",
      drawCRS: "720",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=175'>Invitations to apply for permanent residence under the Express Entry system #175</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=175'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "February 10, 2021 at 14:31:18 UTC",
      drawCutOff: "August 14, 2020 at 06:51:00 UTC",
      drawDistributionAsOn: "February 10, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "174",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=174'>174</a>",
      drawDate: "2021-01-21",
      drawDateFull: "January 21, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "4,626",
      drawCRS: "454",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=174'>Invitations to apply for permanent residence under the Express Entry system #174</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=174'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "January 21, 2021 at 14:22:20 UTC",
      drawCutOff: "July 23, 2020 at 17:32:00 UTC",
      drawDistributionAsOn: "January 21, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "173",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=173'>173</a>",
      drawDate: "2021-01-20",
      drawDateFull: "January 20, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "374",
      drawCRS: "741",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=173'>Invitations to apply for permanent residence under the Express Entry system #173</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=173'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "January 20, 2021 at 14:13:12 UTC",
      drawCutOff: "September 5, 2020 at 19:39:04 UTC",
      drawDistributionAsOn: "January 20, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "172",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=172'>172</a>",
      drawDate: "2021-01-07",
      drawDateFull: "January 7, 2021",
      drawName: "Canadian Experience Class",
      drawSize: "4,750",
      drawCRS: "461",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=172'>Invitations to apply for permanent residence under the Express Entry system #172</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=172'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "January 7, 2021 at 14:15:32 UTC",
      drawCutOff: "September 12, 2020 at 20:46:32 UTC",
      drawDistributionAsOn: "January 7, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "171",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=171'>171</a>",
      drawDate: "2021-01-06",
      drawDateFull: "January 6, 2021",
      drawName: "Provincial Nominee Program",
      drawSize: "250",
      drawCRS: "813",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=171'>Invitations to apply for permanent residence under the Express Entry system #171</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=171'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "January 6, 2021 at 14:39:02 UTC",
      drawCutOff: "November 2, 2020 at 11:11:52 UTC",
      drawDistributionAsOn: "January 6, 2021",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "170",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=170'>170</a>",
      drawDate: "2020-12-23",
      drawDateFull: "December 23, 2020",
      drawName: "No Program Specified",
      drawSize: "5,000",
      drawCRS: "468",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=170'>Invitations to apply for permanent residence under the Express Entry system #170</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=170'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 23, 2020 at 14:40:08 UTC",
      drawCutOff: "June 4, 2020 at 13:39:44 UTC",
      drawDistributionAsOn: "December 23, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "169",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=169'>169</a>",
      drawDate: "2020-12-09",
      drawDateFull: "December 9, 2020",
      drawName: "No Program Specified",
      drawSize: "5,000",
      drawCRS: "469",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=169'>Invitations to apply for permanent residence under the Express Entry system #169</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=169'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 9, 2020 at 14:30:47 UTC",
      drawCutOff: "June 4, 2020 at 22:26:05 UTC",
      drawDistributionAsOn: "December 9, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "168",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=168'>168</a>",
      drawDate: "2020-11-25",
      drawDateFull: "November 25, 2020",
      drawName: "No Program Specified",
      drawSize: "5,000",
      drawCRS: "469",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=168'>Invitations to apply for permanent residence under the Express Entry system #168</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=168'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 25, 2020 at 13:04:56 UTC",
      drawCutOff: "October 15, 2020 at 08:50:10 UTC",
      drawDistributionAsOn: "November 25, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "167",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=167'>167</a>",
      drawDate: "2020-11-18",
      drawDateFull: "November 18, 2020",
      drawName: "No Program Specified",
      drawSize: "5,000",
      drawCRS: "472",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=167'>Invitations to apply for permanent residence under the Express Entry system #167</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=167'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 18, 2020 at 15:27:32 UTC",
      drawCutOff: "October 27, 2020 at 06:18:11 UTC",
      drawDistributionAsOn: "November 18, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "166",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=166'>166</a>",
      drawDate: "2020-11-05",
      drawDateFull: "November 5, 2020",
      drawName: "No Program Specified",
      drawSize: "4,500",
      drawCRS: "478",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=166'>Invitations to apply for permanent residence under the Express Entry system #166</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=166'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 5, 2020 at 12:17:58 UTC",
      drawCutOff: "October 26, 2020 at 22:44:19 UTC",
      drawDistributionAsOn: "November 5, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "165",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=165'>165</a>",
      drawDate: "2020-10-14",
      drawDateFull: "October 14, 2020",
      drawName: "No Program Specified",
      drawSize: "4,500",
      drawCRS: "471",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=165'>Invitations to apply for permanent residence under the Express Entry system #165</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=165'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 14, 2020 at 13:03:22 UTC",
      drawCutOff: "September 1, 2020 at 12:11:23 UTC",
      drawDistributionAsOn: "October 14, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "164",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=164'>164</a>",
      drawDate: "2020-09-30",
      drawDateFull: "September 30, 2020",
      drawName: "No Program Specified",
      drawSize: "4,200",
      drawCRS: "471",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=164'>Invitations to apply for permanent residence under the Express Entry system #164</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=164'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 30, 2020 at 12:37:25 UTC",
      drawCutOff: "March 2, 2020 at 12:47:08 UTC",
      drawDistributionAsOn: "September 30, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "163",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=163'>163</a>",
      drawDate: "2020-09-16",
      drawDateFull: "September 16, 2020",
      drawName: "No Program Specified",
      drawSize: "4,200",
      drawCRS: "472",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=163'>Invitations to apply for permanent residence under the Express Entry system #163</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=163'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 16, 2020 at 07:25:09 UTC",
      drawCutOff: "March 09, 2020 at 13:03:40 UTC",
      drawDistributionAsOn: "September 16, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "162",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=162'>162</a>",
      drawDate: "2020-09-02",
      drawDateFull: "September 2, 2020",
      drawName: "No Program Specified",
      drawSize: "4,200",
      drawCRS: "475",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=162'>Invitations to apply for permanent residence under the Express Entry system #162</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=162'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 2, 2020 at 14:17:32 UTC",
      drawCutOff: "August 16, 2020 at 01:22:18 UTC",
      drawDistributionAsOn: "September 2, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "161",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=161'>161</a>",
      drawDate: "2020-08-20",
      drawDateFull: "August 20, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,300",
      drawCRS: "454",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=161'>Invitations to apply for permanent residence under the Express Entry system #161</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=161'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "August 20, 2020 at 11:10:05 UTC",
      drawCutOff: "July 27, 2020 at 17:05:11 UTC",
      drawDistributionAsOn: "August 20, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "160",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=160'>160</a>",
      drawDate: "2020-08-19",
      drawDateFull: "August 19, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "600",
      drawCRS: "771",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=160'>Invitations to apply for permanent residence under the Express Entry system #160</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=160'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "August 19, 2020 at 12:41:09 UTC",
      drawCutOff: "July 24, 2020 at 11:39:23 UTC",
      drawDistributionAsOn: "August 19, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "159",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=159'>159</a>",
      drawDate: "2020-08-06",
      drawDateFull: "August 6, 2020",
      drawName: "Federal Skilled Trades",
      drawSize: "250",
      drawCRS: "415",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=159'>Invitations to apply for permanent residence under the Express Entry system #159</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=159'>See full text of Ministerial Instruction</a>",
      drawText2: "Federal Skilled Trades",
      drawDateTime: "August 6, 2020 at 13:46:53 UTC",
      drawCutOff: "June 2, 2020 at 09:35:31 UTC",
      drawDistributionAsOn: "August 6, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "158",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=158'>158</a>",
      drawDate: "2020-08-05",
      drawDateFull: "August 5, 2020",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "476",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=158'>Invitations to apply for permanent residence under the Express Entry system #158</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=158'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 5, 2020 at 13:10:05 UTC",
      drawCutOff: "July 27, 2020 at 01:02:47 UTC",
      drawDistributionAsOn: "August 5, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "157",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=157'>157</a>",
      drawDate: "2020-07-23",
      drawDateFull: "July 23, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,343",
      drawCRS: "445",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=157'>Invitations to apply for permanent residence under the Express Entry system #157</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=157'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "July 23, 2020 at 12:24:39 UTC",
      drawCutOff: "July 12, 2020 at 15:16:55 UTC",
      drawDistributionAsOn: "July 23, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "156",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=156'>156</a>",
      drawDate: "2020-07-22",
      drawDateFull: "July 22, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "557",
      drawCRS: "687",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=156'>Invitations to apply for permanent residence under the Express Entry system #156</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=156'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "July 22, 2020 at 12:15:22 UTC",
      drawCutOff: "November 11, 2019 at 08:51:21 UTC",
      drawDistributionAsOn: "July 22, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "155",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=155'>155</a>",
      drawDate: "2020-07-08",
      drawDateFull: "July 8, 2020",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "478",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=155'>Invitations to apply for permanent residence under the Express Entry system #155</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=155'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 8, 2020 at 13:08:41 UTC",
      drawCutOff: "February 11, 2020 at 03:17:13 UTC",
      drawDistributionAsOn: "July 8, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "154",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=154'>154</a>",
      drawDate: "2020-06-25",
      drawDateFull: "June 25, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,508",
      drawCRS: "431",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=154'>Invitations to apply for permanent residence under the Express Entry system #154</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=154'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "June 25, 2020 at 11:29:27 UTC",
      drawCutOff: "April 3, 2020 at 12:56:32 UTC",
      drawDistributionAsOn: "June 25, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "153",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=153'>153</a>",
      drawDate: "2020-06-24",
      drawDateFull: "June 24, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "392",
      drawCRS: "696",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=153'>Invitations to apply for permanent residence under the Express Entry system #153</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=153'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "June 24, 2020 at 11:24:10 UTC",
      drawCutOff: "April 23, 2020 at 10:39:30 UTC",
      drawDistributionAsOn: "June 24, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "152",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=152'>152</a>",
      drawDate: "2020-06-11",
      drawDateFull: "June 11, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,559",
      drawCRS: "437",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=152'>Invitations to apply for permanent residence under the Express Entry system #152</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=152'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "June 11, 2020 at 13:33:58 UTC",
      drawCutOff: "May 25, 2020 at 00:31:43 UTC",
      drawDistributionAsOn: "June 11, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "151",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=151'>151</a>",
      drawDate: "2020-06-10",
      drawDateFull: "June 10, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "341",
      drawCRS: "743",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=151'>Invitations to apply for permanent residence under the Express Entry system #151</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=151'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "June 10, 2020 at 13:49:43 UTC",
      drawCutOff: "April 14, 2020 at 13:25:51 UTC",
      drawDistributionAsOn: "June 10, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "150",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=150'>150</a>",
      drawDate: "2020-05-28",
      drawDateFull: "May 28, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,515",
      drawCRS: "440",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=150'>Invitations to apply for permanent residence under the Express Entry system #150</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=150'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "May 28, 2020 at 11:35:51 UTC",
      drawCutOff: "March 7, 2020 at 10:36:59 UTC",
      drawDistributionAsOn: "May 28, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "149",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=149'>149</a>",
      drawDate: "2020-05-27",
      drawDateFull: "May 27, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "385",
      drawCRS: "757",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=149'>Invitations to apply for permanent residence under the Express Entry system #149</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=149'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 27, 2020 at 11:37:30 UTC",
      drawCutOff: "April 27, 2020 at 10:19:01 UTC",
      drawDistributionAsOn: "May 27, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "148",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=148'>148</a>",
      drawDate: "2020-05-14",
      drawDateFull: "May 14, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,371",
      drawCRS: "447",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=148'>Invitations to apply for permanent residence under the Express Entry system #148</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=148'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "May 15, 2020 at 12:46:53 UTC",
      drawCutOff: "December 23, 2019 at 10:01:06 UTC",
      drawDistributionAsOn: "May 14, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "147",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=147'>147</a>",
      drawDate: "2020-05-13",
      drawDateFull: "May 13, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "529",
      drawCRS: "718",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=147'>Invitations to apply for permanent residence under the Express Entry system #147</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=147'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 13, 2020 at 13:11:44 UTC",
      drawCutOff: "March 19, 2020 at 12:53:21 UTC",
      drawDistributionAsOn: "May 13, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "146",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=146'>146</a>",
      drawDate: "2020-04-30",
      drawDateFull: "April 30, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,311",
      drawCRS: "452",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=146'>Invitations to apply for permanent residence under the Express Entry system #146</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=146'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "May 1, 2020 at 11:33:47 UTC",
      drawCutOff: "October 10, 2019 at 19:15:28 UTC",
      drawDistributionAsOn: "April 30, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "145",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=145'>145</a>",
      drawDate: "2020-04-29",
      drawDateFull: "April 29, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "589",
      drawCRS: "692",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=145'>Invitations to apply for permanent residence under the Express Entry system #145</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=145'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "April 29, 2020 at 11:17:20 UTC",
      drawCutOff: "March 11, 2020 at 13:46:21 UTC",
      drawDistributionAsOn: "April 29, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "144",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=144'>144</a>",
      drawDate: "2020-04-16",
      drawDateFull: "April 16, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,782",
      drawCRS: "455",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=144'>Invitations to apply for permanent residence under the Express Entry system #144</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=144'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "April 16, 2020 at 15:20:02 UTC",
      drawCutOff: "January 20, 2020 at 17:37:33 UTC",
      drawDistributionAsOn: "April 16, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "143",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=143'>143</a>",
      drawDate: "2020-04-15",
      drawDateFull: "April 15, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "118",
      drawCRS: "808",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=143'>Invitations to apply for permanent residence under the Express Entry system #143</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=143'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "April 15, 2020 at 14:31:00 UTC",
      drawCutOff: "March 18, 2020 at 10:30:55 UTC",
      drawDistributionAsOn: "April 15, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "142",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=142'>142</a>",
      drawDate: "2020-04-09",
      drawDateFull: "April 9, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,294",
      drawCRS: "464",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=142'>Invitations to apply for permanent residence under the Express Entry system #142</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=142'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "April 9, 2020 at 17:36:14 UTC",
      drawCutOff: "January 4, 2020 at 12:16:45 UTC",
      drawDistributionAsOn: "April 9, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "141",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=141'>141</a>",
      drawDate: "2020-04-09",
      drawDateFull: "April 9, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "606",
      drawCRS: "698",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=141'>Invitations to apply for permanent residence under the Express Entry system #141</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=141'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "April 9, 2020 at 14:14:53 UTC",
      drawCutOff: "December 18, 2019 at 11:29:51 UTC",
      drawDistributionAsOn: "April 9, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "140",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=140'>140</a>",
      drawDate: "2020-03-23",
      drawDateFull: "March 23, 2020",
      drawName: "Canadian Experience Class",
      drawSize: "3,232",
      drawCRS: "467",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=140'>Invitations to apply for permanent residence under the Express Entry system #140</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=140'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "March 23, 2020 at 16:12:27 UTC",
      drawCutOff: "November 25, 2019 at 14:00:35 UTC",
      drawDistributionAsOn: "March 23, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "139",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=139'>139</a>",
      drawDate: "2020-03-18",
      drawDateFull: "March 18, 2020",
      drawName: "Provincial Nominee Program",
      drawSize: "668",
      drawCRS: "720",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=139'>Invitations to apply for permanent residence under the Express Entry system #139</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=139'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "March 19, 2020 at 02:04:06 UTC",
      drawCutOff: "March 19, 2020 at 02:04:06 UTC",
      drawDistributionAsOn: "March 18, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "138",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=138'>138</a>",
      drawDate: "2020-03-04",
      drawDateFull: "March 4, 2020",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "471",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=138'>Invitations to apply for permanent residence under the Express Entry system #138</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=138'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 4, 2020 at 13:44:16 UTC",
      drawCutOff: "February 24, 2020 at 06:02:57 UTC",
      drawDistributionAsOn: "March 4, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "137",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=137'>137</a>",
      drawDate: "2020-02-19",
      drawDateFull: "February 19, 2020",
      drawName: "No Program Specified",
      drawSize: "4,500",
      drawCRS: "470",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=137'>Invitations to apply for permanent residence under the Express Entry system #137</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=137'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 19, 2020 at 13:19:54 UTC",
      drawCutOff: "January 13, 2020 at 10:52:52 UTC",
      drawDistributionAsOn: "February 19, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "136",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=136'>136</a>",
      drawDate: "2020-02-05",
      drawDateFull: "February 5, 2020",
      drawName: "No Program Specified",
      drawSize: "3,500",
      drawCRS: "472",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=136'>Invitations to apply for permanent residence under the Express Entry system #136</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=136'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 5, 2020 at 13:11:46 UTC",
      drawCutOff: "January 30, 2020 at 07:36:26 UTC",
      drawDistributionAsOn: "February 5, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "135",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=135'>135</a>",
      drawDate: "2020-01-22",
      drawDateFull: "January 22, 2020",
      drawName: "No Program Specified",
      drawSize: "3,400",
      drawCRS: "471",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=135'>Invitations to apply for permanent residence under the Express Entry system #135</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=135'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 22, 2020 at 13:35:06 UTC",
      drawCutOff: "May 9, 2019 at 01:16:02 UTC",
      drawDistributionAsOn: "January 22, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "134",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=134'>134</a>",
      drawDate: "2020-01-08",
      drawDateFull: "January 8, 2020",
      drawName: "No Program Specified",
      drawSize: "3,400",
      drawCRS: "473",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=134'>Invitations to apply for permanent residence under the Express Entry system #134</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=134'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 8, 2020 at 13:33:44 UTC",
      drawCutOff: "December 27, 2019 at 13:35:09 UTC",
      drawDistributionAsOn: "January 8, 2020",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "133",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=133'>133</a>",
      drawDate: "2019-12-19",
      drawDateFull: "December 19, 2019",
      drawName: "No Program Specified",
      drawSize: "3,200",
      drawCRS: "469",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=133'>Invitations to apply for permanent residence under the Express Entry system #133</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=133'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 19, 2019 at 13:21:53 UTC",
      drawCutOff: "November 14, 2019 at 08:41:33 UTC",
      drawDistributionAsOn: "December 19, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "132",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=132'>132</a>",
      drawDate: "2019-12-11",
      drawDateFull: "December 11, 2019",
      drawName: "No Program Specified",
      drawSize: "3,200",
      drawCRS: "472",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=132'>Invitations to apply for permanent residence under the Express Entry system #132</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=132'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 11, 2019 at 13:26:03 UTC",
      drawCutOff: "December 6, 2019 at 03:03:49 UTC",
      drawDistributionAsOn: "December 11, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "131",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=131'>131</a>",
      drawDate: "2019-11-27",
      drawDateFull: "November 27, 2019",
      drawName: "No Program Specified",
      drawSize: "3,600",
      drawCRS: "471",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=131'>Invitations to apply for permanent residence under the Express Entry system #131</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=131'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 27, 2019 at 12:44:41 UTC",
      drawCutOff: "November 11, 2019 at 6:29:10 UTC",
      drawDistributionAsOn: "November 27, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "130",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=130'>130</a>",
      drawDate: "2019-11-13",
      drawDateFull: "November 13, 2019",
      drawName: "No Program Specified",
      drawSize: "3,600",
      drawCRS: "472",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=130'>Invitations to apply for permanent residence under the Express Entry system #130</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=130'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 13, 2019 at 12:40:01 UTC",
      drawCutOff: "October 8, 2019 at 10:46:01 UTC",
      drawDistributionAsOn: "November 13, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "129",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=129'>129</a>",
      drawDate: "2019-10-30",
      drawDateFull: "October 30, 2019",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "475",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=129'>Invitations to apply for permanent residence under the Express Entry system #129</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=129'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 30, 2019 at 12:50:09 UTC",
      drawCutOff: "August 29, 2019 at 03:50:24 UTC",
      drawDistributionAsOn: "October 30, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "128",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=128'>128</a>",
      drawDate: "2019-10-16",
      drawDateFull: "October 16, 2019",
      drawName: "Federal Skilled Trades",
      drawSize: "500",
      drawCRS: "357",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=128'>Invitations to apply for permanent residence under the Express Entry system #128</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=128'>See full text of Ministerial Instruction</a>",
      drawText2: "Federal Skilled Trades",
      drawDateTime: "October 16, 2019 at 12:56:58 UTC",
      drawCutOff: "March 4, 2019 at 20:36:42 UTC",
      drawDistributionAsOn: "October 16, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "127",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=127'>127</a>",
      drawDate: "2019-10-02",
      drawDateFull: "October 2, 2019",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "464",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=127'>Invitations to apply for permanent residence under the Express Entry system #127</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=127'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 2, 2019 at 12:16:25 UTC",
      drawCutOff: "September 20, 2019 at 15:05:04 UTC",
      drawDistributionAsOn: "October 2, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "126",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=126'>126</a>",
      drawDate: "2019-09-18",
      drawDateFull: "September 18, 2019",
      drawName: "No Program Specified",
      drawSize: "3,600",
      drawCRS: "462",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=126'>Invitations to apply for permanent residence under the Express Entry system #126</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=126'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 18, 2019 at 17:52:51 UTC",
      drawCutOff: "August 29, 2019 at 07:57:13 UTC",
      drawDistributionAsOn: "September 18, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "125",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=125'>125</a>",
      drawDate: "2019-09-04",
      drawDateFull: "September 4, 2019",
      drawName: "No Program Specified",
      drawSize: "3,600",
      drawCRS: "463",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=125'>Invitations to apply for permanent residence under the Express Entry system #125</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=125'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 4, 2019 at 13:36:34 UTC",
      drawCutOff: "August 23, 2019 at 13:33:34 UTC",
      drawDistributionAsOn: "September 4, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "124",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=124'>124</a>",
      drawDate: "2019-08-20",
      drawDateFull: "August 20, 2019",
      drawName: "No Program Specified",
      drawSize: "3,600",
      drawCRS: "457",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=124'>Invitations to apply for permanent residence under the Express Entry system #124</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=124'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 20, 2019 at 11:51:30 UTC",
      drawCutOff: "March 24, 2019 at 15:32:42 UTC",
      drawDistributionAsOn: "August 20, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "123",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=123'>123</a>",
      drawDate: "2019-08-12",
      drawDateFull: "August 12, 2019",
      drawName: "No Program Specified",
      drawSize: "3,600",
      drawCRS: "466",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=123'>Invitations to apply for permanent residence under the Express Entry system #123</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=123'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 12, 2019 at 12:36:51 UTC",
      drawCutOff: "August 6, 2019 at 12:14:53 UTC",
      drawDistributionAsOn: "August 12, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "122",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=122'>122</a>",
      drawDate: "2019-07-24",
      drawDateFull: "July 24, 2019",
      drawName: "No Program Specified",
      drawSize: "3,600",
      drawCRS: "459",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=122'>Invitations to apply for permanent residence under the Express Entry system #122</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=122'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 24, 2019 at 12:55:38 UTC",
      drawCutOff: "May 25, 2019 at 14:40:52 UTC",
      drawDistributionAsOn: "July 24, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "121",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=121'>121</a>",
      drawDate: "2019-07-10",
      drawDateFull: "July 10, 2019",
      drawName: "No Program Specified",
      drawSize: "3,600",
      drawCRS: "460",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=121'>Invitations to apply for permanent residence under the Express Entry system #121</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=121'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 10, 2019 at 12:31:36 UTC",
      drawCutOff: "June 11, 2019 at 00:27:18 UTC",
      drawDistributionAsOn: "July 10, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "120",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=120'>120</a>",
      drawDate: "2019-06-26",
      drawDateFull: "June 26, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "462",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=120'>Invitations to apply for permanent residence under the Express Entry system #120</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=120'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 26, 2019 at 12:30:54 UTC",
      drawCutOff: "May 11, 2019 at 17:40:54 UTC",
      drawDistributionAsOn: "June 26, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "119",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=119'>119</a>",
      drawDate: "2019-06-12",
      drawDateFull: "June 12, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "465",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=119'>Invitations to apply for permanent residence under the Express Entry system #119</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=119'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 12, 2019 at 11:59:22 UTC",
      drawCutOff: "June 6, 2019 at 13:51:59 UTC",
      drawDistributionAsOn: "June 12, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "118",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=118'>118</a>",
      drawDate: "2019-05-29",
      drawDateFull: "May 29, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "470",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=118'>Invitations to apply for permanent residence under the Express Entry system #118</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=118'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 29, 2019 at 12:09:37 UTC",
      drawCutOff: "May 23, 2019 at 10:18:56 UTC",
      drawDistributionAsOn: "May 29, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "117",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=117'>117</a>",
      drawDate: "2019-05-15",
      drawDateFull: "May 15, 2019",
      drawName: "Federal Skilled Trades",
      drawSize: "500",
      drawCRS: "332",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=117'>Invitations to apply for permanent residence under the Express Entry system #117</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=117'>See full text of Ministerial Instruction</a>",
      drawText2: "Federal Skilled Trades",
      drawDateTime: "May 15, 2019 at 11:23:38 UTC",
      drawCutOff: "August 29, 2018 at 8:32:03 UTC",
      drawDistributionAsOn: "May 15, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "116",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=116'>116</a>",
      drawDate: "2019-05-01",
      drawDateFull: "May 1, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "450",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=116'>Invitations to apply for permanent residence under the Express Entry system #116</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=116'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 1, 2019 at 12:03:02 UTC",
      drawCutOff: "November 5, 2018 at 12:27:07 UTC",
      drawDistributionAsOn: "May 1, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "115",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=115'>115</a>",
      drawDate: "2019-04-17",
      drawDateFull: "April 17, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "451",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=115'>Invitations to apply for permanent residence under the Express Entry system #115</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=115'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 17, 2019 at 15:26:33 UTC",
      drawCutOff: "April 2, 2019 at 15:01:49 UTC",
      drawDistributionAsOn: "April 17, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "114",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=114'>114</a>",
      drawDate: "2019-04-03",
      drawDateFull: "April 3, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "451",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=114'>Invitations to apply for permanent residence under the Express Entry system #114</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=114'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 3, 2019 at 17:37:40 UTC",
      drawCutOff: "January 9, 2019 at 7:35:09 UTC",
      drawDistributionAsOn: "April 3, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "113",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=113'>113</a>",
      drawDate: "2019-03-20",
      drawDateFull: "March 20, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "452",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=113'>Invitations to apply for permanent residence under the Express Entry system #113</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=113'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 20, 2019 at 11:44:52 UTC",
      drawCutOff: "February 7, 2019 at 9:59:25 UTC",
      drawDistributionAsOn: "March 20, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "112",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=112'>112</a>",
      drawDate: "2019-03-06",
      drawDateFull: "March 6, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "454",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=112'>Invitations to apply for permanent residence under the Express Entry system #112</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=112'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 6, 2019 at 14:56:03 UTC",
      drawCutOff: "February 11, 2019 at 17:21:27 UTC",
      drawDistributionAsOn: "March 6, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "111",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=111'>111</a>",
      drawDate: "2019-02-20",
      drawDateFull: "February 20, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "457",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=111'>Invitations to apply for permanent residence under the Express Entry system #111</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=111'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 20, 2019 at 01:43:14 UTC",
      drawCutOff: "December 6, 2018 at 12:38:11 UTC",
      drawDistributionAsOn: "February 20, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "110",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=110'>110</a>",
      drawDate: "2019-01-30",
      drawDateFull: "January 30, 2019",
      drawName: "No Program Specified",
      drawSize: "3,350",
      drawCRS: "438",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=110'>Invitations to apply for permanent residence under the Express Entry system #110</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=110'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 30, 2019 at 12:09:10 UTC",
      drawCutOff: "April 26, 2018 at 05:00:46 UTC",
      drawDistributionAsOn: "January 30, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "109",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=109'>109</a>",
      drawDate: "2019-01-23",
      drawDateFull: "January 23, 2019",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "443",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=109'>Invitations to apply for permanent residence under the Express Entry system #109</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=109'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 23, 2019 at 14:19:24 UTC",
      drawCutOff: "January 2, 2019 at 03:55:26 UTC",
      drawDistributionAsOn: "January 23, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "108",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=108'>108</a>",
      drawDate: "2019-01-09",
      drawDateFull: "January 9, 2019",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "449",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=108'>Invitations to apply for permanent residence under the Express Entry system #108</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=108'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 10, 2019 at 12:20:46 UTC",
      drawCutOff: "January 2, 2019 at 11:40:53 UTC",
      drawDistributionAsOn: "January 9, 2019",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "107",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=107'>107</a>",
      drawDate: "2018-12-19",
      drawDateFull: "December 19, 2018",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "439",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=107'>Invitations to apply for permanent residence under the Express Entry system #107</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=107'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 19, 2018 at 12:35:17 UTC",
      drawCutOff: "February 6, 2018 at 19:31:37 UTC",
      drawDistributionAsOn: "December 19, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "106",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=106'>106</a>",
      drawDate: "2018-12-12",
      drawDateFull: "December 12, 2018",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "445",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=106'>Invitations to apply for permanent residence under the Express Entry system #106</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=106'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 12, 2018 at 14:10:54 UTC",
      drawCutOff: "November 28, 2018 at 09:51:43 UTC",
      drawDistributionAsOn: "December 12, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "105",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=105'>105</a>",
      drawDate: "2018-11-28",
      drawDateFull: "November 28, 2018",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "445",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=105'>Invitations to apply for permanent residence under the Express Entry system #105</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=105'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 28, 2018 at 12:14:58 UTC",
      drawCutOff: "October 22, 2018 at 14:40:13 UTC",
      drawDistributionAsOn: "November 28, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "104",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=104'>104</a>",
      drawDate: "2018-11-14",
      drawDateFull: "November 14, 2018",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "449",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=104'>Invitations to apply for permanent residence under the Express Entry system #104</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=104'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 15, 2018 at 14:06:07 UTC",
      drawCutOff: "September 16, 2018 at 12:41:42 UTC",
      drawDistributionAsOn: "November 14, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "103",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=103'>103</a>",
      drawDate: "2018-10-29",
      drawDateFull: "October 29, 2018",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "442",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=103'>Invitations to apply for permanent residence under the Express Entry system #103</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=103'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 29, 2018 at 16:36:30 UTC",
      drawCutOff: "October 16, 2018 at 06:09:04 UTC",
      drawDistributionAsOn: "October 29, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "102",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=102'>102</a>",
      drawDate: "2018-10-15",
      drawDateFull: "October 15, 2018",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "440",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=102'>Invitations to apply for permanent residence under the Express Entry system #102</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=102'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 15, 2018 at 12:11:46 UTC",
      drawCutOff: "September 18, 2018 at 11:06:29 UTC",
      drawDistributionAsOn: "October 15, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "101",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=101'>101</a>",
      drawDate: "2018-10-03",
      drawDateFull: "October 3, 2018",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "445",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=101'>Invitations to apply for permanent residence under the Express Entry system #101</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=101'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 3, 2018 at 11:20:04 UTC",
      drawCutOff: "October 2, 2018 at 04:02:11 UTC",
      drawDistributionAsOn: "October 3, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "100",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=100'>100</a>",
      drawDate: "2018-09-24",
      drawDateFull: "September 24, 2018",
      drawName: "Federal Skilled Trades",
      drawSize: "400",
      drawCRS: "284",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=100'>Invitations to apply for permanent residence under the Express Entry system #100</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=100'>See full text of Ministerial Instruction</a>",
      drawText2: "Federal Skilled Trades",
      drawDateTime: "September 24, 2018 at 05:34:21 UTC",
      drawCutOff: "February 18, 2018 at 12:31:16 UTC",
      drawDistributionAsOn: "September 24, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "99",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=99'>99</a>",
      drawDate: "2018-09-19",
      drawDateFull: "September 19, 2018",
      drawName: "No Program Specified",
      drawSize: "3,500",
      drawCRS: "441",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=99'>Invitations to apply for permanent residence under the Express Entry system #99</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=99'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 19, 2018 at 11:29:46 UTC",
      drawCutOff: "September 6, 2018 at 10:28:45 UTC",
      drawDistributionAsOn: "September 19, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "98",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=98'>98</a>",
      drawDate: "2018-09-05",
      drawDateFull: "September 5, 2018",
      drawName: "No Program Specified",
      drawSize: "3,900",
      drawCRS: "440",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=98'>Invitations to apply for permanent residence under the Express Entry system #98</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=98'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 5, 2018 at 13:10:19 UTC",
      drawCutOff: "April 29, 2018 at 14:28:34 UTC",
      drawDistributionAsOn: "September 5, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "97",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=97'>97</a>",
      drawDate: "2018-08-22",
      drawDateFull: "August 22, 2018",
      drawName: "No Program Specified",
      drawSize: "3,750",
      drawCRS: "440",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=97'>Invitations to apply for permanent residence under the Express Entry system #97</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=97'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 22, 2018 at 13:19:10 UTC",
      drawCutOff: "February 21, 2018 at 11:28:03 UTC",
      drawDistributionAsOn: "August 22, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "96",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=96'>96</a>",
      drawDate: "2018-08-08",
      drawDateFull: "August 8, 2018",
      drawName: "No Program Specified",
      drawSize: "3,750",
      drawCRS: "440",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=96'>Invitations to apply for permanent residence under the Express Entry system #96</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=96'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 8, 2018 at 11:56:30 UTC",
      drawCutOff: "January 31, 2018 at 00:06:40 UTC",
      drawDistributionAsOn: "August 8, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "95",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=95'>95</a>",
      drawDate: "2018-07-25",
      drawDateFull: "July 25, 2018",
      drawName: "No Program Specified",
      drawSize: "3,750",
      drawCRS: "441",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=95'>Invitations to apply for permanent residence under the Express Entry system #95</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=95'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 25, 2018 at 12:43:04 UTC",
      drawCutOff: "December 31, 2017 at 12:14:21 UTC",
      drawDistributionAsOn: "July 25, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "94",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=94'>94</a>",
      drawDate: "2018-07-11",
      drawDateFull: "July 11, 2018",
      drawName: "No Program Specified",
      drawSize: "3,750",
      drawCRS: "442",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=94'>Invitations to apply for permanent residence under the Express Entry system #94</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=94'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 11, 2018 at 12:09:46 UTC",
      drawCutOff: "June 26, 2018 at 13:18:55 UTC",
      drawDistributionAsOn: "July 11, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "93",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=93'>93</a>",
      drawDate: "2018-06-25",
      drawDateFull: "June 25, 2018",
      drawName: "No Program Specified",
      drawSize: "3,750",
      drawCRS: "442",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=93'>Invitations to apply for permanent residence under the Express Entry system #93</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=93'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 25, 2018 at 12:11:51 UTC",
      drawCutOff: "January 28, 2018 at 21:06:11 UTC",
      drawDistributionAsOn: "June 25, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "92",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=92'>92</a>",
      drawDate: "2018-06-13",
      drawDateFull: "June 13, 2018",
      drawName: "No Program Specified",
      drawSize: "3,750",
      drawCRS: "451",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=92'>Invitations to apply for permanent residence under the Express Entry system #92</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=92'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 13, 2018 at 11:31:36 UTC",
      drawCutOff: "July 21, 2017 at 07:01:28 UTC",
      drawDistributionAsOn: "June 13, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "91b",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=91b'>91b</a>",
      drawDate: "2018-05-30",
      drawDateFull: "May 30, 2018",
      drawName: "Provincial Nominee Program",
      drawSize: "200",
      drawCRS: "902",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=91b'>Invitations to apply for permanent residence under the Express Entry system #91b</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=91b'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 30, 2018 at 14:52:06 UTC",
      drawCutOff: "March 23, 2018 at 05:38:02 UTC",
      drawDistributionAsOn: "May 30, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "91a",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=91a'>91a</a>",
      drawDate: "2018-05-30",
      drawDateFull: "May 30, 2018",
      drawName: "Federal Skilled Trades",
      drawSize: "500",
      drawCRS: "288",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=91a'>Invitations to apply for permanent residence under the Express Entry system #91a</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=91a'>See full text of Ministerial Instruction</a>",
      drawText2: "Federal Skilled Trades",
      drawDateTime: "May 30, 2018 at 14:52:06 UTC",
      drawCutOff: "June 7, 2017 at 20:11:13 UTC",
      drawDistributionAsOn: "May 30, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "90",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=90'>90</a>",
      drawDate: "2018-05-23",
      drawDateFull: "May 23, 2018",
      drawName: "No Program Specified",
      drawSize: "3,500",
      drawCRS: "440",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=90'>Invitations to apply for permanent residence under the Express Entry system #90</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=90'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 23, 2018 at 11:20:42 UTC",
      drawCutOff: "December 30, 2017 at 06:39:40 UTC",
      drawDistributionAsOn: "May 23, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "89",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=89'>89</a>",
      drawDate: "2018-05-09",
      drawDateFull: "May 9, 2018",
      drawName: "No Program Specified",
      drawSize: "3,500",
      drawCRS: "441",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=89'>Invitations to apply for permanent residence under the Express Entry system #89</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=89'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 9, 2018 at 12:28:41 UTC",
      drawCutOff: "December 12, 2017 at 01:51:38 UTC",
      drawDistributionAsOn: "May 9, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "88",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=88'>88</a>",
      drawDate: "2018-04-25",
      drawDateFull: "April 25, 2018",
      drawName: "No Program Specified",
      drawSize: "3,500",
      drawCRS: "441",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=88'>Invitations to apply for permanent residence under the Express Entry system #88</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=88'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 25, 2018 at 12:40:15 UTC",
      drawCutOff: "December 8, 2017 at 10:25:33 UTC",
      drawDistributionAsOn: "April 25, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "87",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=87'>87</a>",
      drawDate: "2018-04-11",
      drawDateFull: "April 11, 2018",
      drawName: "No Program Specified",
      drawSize: "3,500",
      drawCRS: "444",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=87'>Invitations to apply for permanent residence under the Express Entry system #87</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=87'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 11, 2018 at 12:50:17 UTC",
      drawCutOff: "March 26, 2018 at 08:25:40 UTC",
      drawDistributionAsOn: "April 11, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "86",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=86'>86</a>",
      drawDate: "2018-03-26",
      drawDateFull: "March 26, 2018",
      drawName: "No Program Specified",
      drawSize: "3,000",
      drawCRS: "446",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=86'>Invitations to apply for permanent residence under the Express Entry system #86</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=86'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 26, 2018 at 12:00:18 UTC",
      drawCutOff: "February 23, 2018 at 08:06:19 UTC",
      drawDistributionAsOn: "March 26, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "85",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=85'>85</a>",
      drawDate: "2018-03-14",
      drawDateFull: "March 14, 2018",
      drawName: "No Program Specified",
      drawSize: "3,000",
      drawCRS: "456",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=85'>Invitations to apply for permanent residence under the Express Entry system #85</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=85'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 14, 2018 at 12:08:58 UTC",
      drawCutOff: "March 3, 2018 at 02:12:11 UTC",
      drawDistributionAsOn: "March 14, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "84",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=84'>84</a>",
      drawDate: "2018-02-21",
      drawDateFull: "February 21, 2018",
      drawName: "No Program Specified",
      drawSize: "3,000",
      drawCRS: "442",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=84'>Invitations to apply for permanent residence under the Express Entry system #84</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=84'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 21, 2018 at 13:29:23 UTC",
      drawCutOff: "February 11, 2018 at 17:03:32 UTC",
      drawDistributionAsOn: "February 21, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "83",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=83'>83</a>",
      drawDate: "2018-02-07",
      drawDateFull: "February 7, 2018",
      drawName: "No Program Specified",
      drawSize: "3,000",
      drawCRS: "442",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=83'>Invitations to apply for permanent residence under the Express Entry system #83</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=83'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 7, 2018 at 16:55:01 UTC",
      drawCutOff: "May 29, 2017 at 07:01:15 UTC",
      drawDistributionAsOn: "February 7, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "82",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=82'>82</a>",
      drawDate: "2018-01-24",
      drawDateFull: "January 24, 2018",
      drawName: "No Program Specified",
      drawSize: "2,750",
      drawCRS: "444",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=82'>Invitations to apply for permanent residence under the Express Entry system #82</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=82'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 24, 2018 at 13:38:00 UTC",
      drawCutOff: " January 17, 2018 at 12:33:00 UTC",
      drawDistributionAsOn: "January 24, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "81",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=81'>81</a>",
      drawDate: "2018-01-10",
      drawDateFull: "January 10, 2018",
      drawName: "No Program Specified",
      drawSize: "2,750",
      drawCRS: "446",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=81'>Invitations to apply for permanent residence under the Express Entry system #81</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=81'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 10, 2018 at 12:15:38 UTC",
      drawCutOff: "November 18, 2017 at 6:30:42 UTC",
      drawDistributionAsOn: "January 10, 2018",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "80",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=80'>80</a>",
      drawDate: "2017-12-20",
      drawDateFull: "December 20, 2017",
      drawName: "No Program Specified",
      drawSize: "2,750",
      drawCRS: "446",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=80'>Invitations to apply for permanent residence under the Express Entry system #80</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=80'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 20, 2017 at 13:29:55 UTC",
      drawCutOff: "October 02, 2017 at 14:56:53 UTC",
      drawDistributionAsOn: "December 20, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "79",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=79'>79</a>",
      drawDate: "2017-12-06",
      drawDateFull: "December 6, 2017",
      drawName: "No Program Specified",
      drawSize: "2,750",
      drawCRS: "452",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=79'>Invitations to apply for permanent residence under the Express Entry system #79</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=79'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 6, 2017 at 14:00:00 UTC",
      drawCutOff: "November 16, 2017 at 04:20:20 UTC",
      drawDistributionAsOn: "December 6, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "78",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=78'>78</a>",
      drawDate: "2017-11-15",
      drawDateFull: "November 15, 2017",
      drawName: "No Program Specified",
      drawSize: "2,750",
      drawCRS: "439",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=78'>Invitations to apply for permanent residence under the Express Entry system #78</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=78'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 15, 2017 at 17:54:19 UTC",
      drawCutOff: "October 19, 2017 at 12:02:28 UTC",
      drawDistributionAsOn: "November 15, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "77",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=77'>77</a>",
      drawDate: "2017-11-08",
      drawDateFull: "November 8, 2017",
      drawName: "No Program Specified",
      drawSize: "2,000",
      drawCRS: "458",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=77'>Invitations to apply for permanent residence under the Express Entry system #77</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations.html?q=77'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 8, 2017 at 13:40:54 UTC",
      drawCutOff: "October 23, 2017 at 15:58:27 UTC",
      drawDistributionAsOn: "November 8, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "76",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-76.html'>76</a>",
      drawDate: "2017-11-01",
      drawDateFull: "November 1, 2017",
      drawName: "Federal Skilled Trades",
      drawSize: "505",
      drawCRS: "241",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-76.html'>Invitations to apply for permanent residence under the Express Entry system #76</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-76.html'>See full text of Ministerial Instruction</a>",
      drawText2: "Federal Skilled Trades",
      drawDateTime: "November 1, 2017, at 12:55:44 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "November 1, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "75",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-75.html'>75</a>",
      drawDate: "2017-11-01",
      drawDateFull: "November 1, 2017",
      drawName: "Provincial Nominee Program",
      drawSize: "290",
      drawCRS: "673",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-75.html'>Invitations to apply for permanent residence under the Express Entry system #75</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-75.html'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "November 1, 2017, at 12:55:44 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "November 1, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "74",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-74.html'>74</a>",
      drawDate: "2017-10-18",
      drawDateFull: "October 18, 2017",
      drawName: "No Program Specified",
      drawSize: "2,757",
      drawCRS: "436",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-74.html'>Invitations to apply for permanent residence under the Express Entry system #74</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-74.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 18, 2017 at 11:12:41 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "October 18, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "73",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-73.html'>73</a>",
      drawDate: "2017-10-04",
      drawDateFull: "October 4, 2017",
      drawName: "No Program Specified",
      drawSize: "2,801",
      drawCRS: "438",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-73.html'>Invitations to apply for permanent residence under the Express Entry system #73</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-73.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 4, 2017 at 12:22:22 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "October 4, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "72",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-72.html'>72</a>",
      drawDate: "2017-09-20",
      drawDateFull: "September 20, 2017",
      drawName: "No Program Specified",
      drawSize: "2,871",
      drawCRS: "433",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-72.html'>Invitations to apply for permanent residence under the Express Entry system #72</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-72.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 20, 2017 at 12:39:23 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "September 20, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "71",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-71.html'>71</a>",
      drawDate: "2017-09-06",
      drawDateFull: "September 6, 2017",
      drawName: "No Program Specified",
      drawSize: "2,772",
      drawCRS: "435",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-71.html'>Invitations to apply for permanent residence under the Express Entry system #71</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-71.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 6, 2017 at 11:08:58 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "September 6, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "70",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-70.html'>70</a>",
      drawDate: "2017-08-23",
      drawDateFull: "August 23, 2017",
      drawName: "No Program Specified",
      drawSize: "3,035",
      drawCRS: "434",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-70.html'>Invitations to apply for permanent residence under the Express Entry system #70</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-70.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 23, 2017 at 12:14:16 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "August 23, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "69",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-69.html'>69</a>",
      drawDate: "2017-08-09",
      drawDateFull: "August 9, 2017",
      drawName: "No Program Specified",
      drawSize: "2,991",
      drawCRS: "433",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-69.html'>Invitations to apply for permanent residence under the Express Entry system #69</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-69.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 9, 2017 at 12:15:03 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "August 9, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "68",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-68.html'>68</a>",
      drawDate: "2017-08-02",
      drawDateFull: "August 2, 2017",
      drawName: "No Program Specified",
      drawSize: "3,264",
      drawCRS: "441",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-68.html'>Invitations to apply for permanent residence under the Express Entry system #68</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-68.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 2, 2017 at 12:22:55 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "August 2, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "67",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-67.html'>67</a>",
      drawDate: "2017-07-12",
      drawDateFull: "July 12, 2017",
      drawName: "No Program Specified",
      drawSize: "3,202",
      drawCRS: "440",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-67.html'>Invitations to apply for permanent residence under the Express Entry system #67</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-67.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 12, 2017 at 16:31:04 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "July 12, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "66",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-66.html'>66</a>",
      drawDate: "2017-06-28",
      drawDateFull: "June 28, 2017",
      drawName: "No Program Specified",
      drawSize: "3,409",
      drawCRS: "449",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-66.html'>Invitations to apply for permanent residence under the Express Entry system #66</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-66.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 28, 2017 at 13:17:07 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "June 28, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "65",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-65.html'>65</a>",
      drawDate: "2017-05-31",
      drawDateFull: "May 31, 2017",
      drawName: "No Program Specified",
      drawSize: "3,877",
      drawCRS: "413",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-64.html'>Invitations to apply for permanent residence under the Express Entry system #65</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-64.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 31, 2017 at 12:13:35 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "May 31, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "64",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-64.html'>64</a>",
      drawDate: "2017-05-26",
      drawDateFull: "May 26, 2017",
      drawName: "Federal Skilled Trades",
      drawSize: "400",
      drawCRS: "199",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-64.html'>Invitations to apply for permanent residence under the Express Entry system #64</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-64.html'>See full text of Ministerial Instruction</a>",
      drawText2: "Federal Skilled Trades",
      drawDateTime: "May 26, 2017, at 16:35:48 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "May 26, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "63",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-63.html'>63</a>",
      drawDate: "2017-05-26",
      drawDateFull: "May 26, 2017",
      drawName: "Provincial Nominee Program",
      drawSize: "143",
      drawCRS: "775",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-63.html'>Invitations to apply for permanent residence under the Express Entry system #63</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-63.html'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "May 26, 2017, at 16:35:48 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "May 26, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "62",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-62.html'>62</a>",
      drawDate: "2017-05-17",
      drawDateFull: "May 17, 2017",
      drawName: "No Program Specified",
      drawSize: "3,687",
      drawCRS: "415",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-62.html'>Invitations to apply for permanent residence under the Express Entry system #62</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-62.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 17, 2017 at 13:17:03 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "May 17, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "61",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-61.html'>61</a>",
      drawDate: "2017-05-04",
      drawDateFull: "May 4, 2017",
      drawName: "No Program Specified",
      drawSize: "3,796",
      drawCRS: "423",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-61.html'>Invitations to apply for permanent residence under the Express Entry system #61</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-61.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 4, 2017 at 12:20:49 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "May 4, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "60",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-60.html'>60</a>",
      drawDate: "2017-04-19",
      drawDateFull: "April 19, 2017",
      drawName: "No Program Specified",
      drawSize: "3,665",
      drawCRS: "415",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-60.html'>Invitations to apply for permanent residence under the Express Entry system #60</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-60.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 19, 2017 at 12:23:45 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "April 19, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "59",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-59.html'>59</a>",
      drawDate: "2017-04-12",
      drawDateFull: "April 12, 2017",
      drawName: "No Program Specified",
      drawSize: "3,923",
      drawCRS: "423",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-59.html'>Invitations to apply for permanent residence under the Express Entry system #59</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-59.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 12, 2017 at 17:24:01 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "April 12, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "58",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-58.html'>58</a>",
      drawDate: "2017-04-05",
      drawDateFull: "April 5, 2017",
      drawName: "No Program Specified",
      drawSize: "3,753",
      drawCRS: "431",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-58.html'>Invitations to apply for permanent residence under the Express Entry system #58</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-58.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 5, 2017 at 12:09:10 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "April 5, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "57",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-57.html'>57</a>",
      drawDate: "2017-03-24",
      drawDateFull: "March 24, 2017",
      drawName: "No Program Specified",
      drawSize: "3,749",
      drawCRS: "441",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-57.html'>Invitations to apply for permanent residence under the Express Entry system #57</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-57.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 24, 2017 at 12:38:04 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "March 24, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "56",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-56.html'>56</a>",
      drawDate: "2017-03-01",
      drawDateFull: "March 1, 2017",
      drawName: "No Program Specified",
      drawSize: "3,884",
      drawCRS: "434",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-56.html'>Invitations to apply for permanent residence under the Express Entry system #56</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-56.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 1, 2017 at 13:32:31 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "March 1, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "55",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-55.html'>55</a>",
      drawDate: "2017-02-22",
      drawDateFull: "February 22, 2017",
      drawName: "No Program Specified",
      drawSize: "3,611",
      drawCRS: "441",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-55.html'>Invitations to apply for permanent residence under the Express Entry system #55</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-55.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 22, 2017 at 13:10:45 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "February 22, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "54",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-54.html'>54</a>",
      drawDate: "2017-02-08",
      drawDateFull: "February 8, 2017",
      drawName: "No Program Specified",
      drawSize: "3,644",
      drawCRS: "447",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-54.html'>Invitations to apply for permanent residence under the Express Entry system #54</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-54.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 8, 2017 at 13:23:27 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "February 8, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "53",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-53.html'>53</a>",
      drawDate: "2017-01-25",
      drawDateFull: "January 25, 2017",
      drawName: "No Program Specified",
      drawSize: "3,508",
      drawCRS: "453",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-53.html'>Invitations to apply for permanent residence under the Express Entry system #53</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-53.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 25, 2017 at 13:13:53 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "January 25, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "52",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-52.html'>52</a>",
      drawDate: "2017-01-11",
      drawDateFull: "January 11, 2017",
      drawName: "No Program Specified",
      drawSize: "3,334",
      drawCRS: "459",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-52.html'>Invitations to apply for permanent residence under the Express Entry system #52</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-52.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 11, 2017 at 15:16:33 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "January 11, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "51",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-51.html'>51</a>",
      drawDate: "2017-01-04",
      drawDateFull: "January 4, 2017",
      drawName: "No Program Specified",
      drawSize: "2,902",
      drawCRS: "468",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-51.html'>Invitations to apply for permanent residence under the Express Entry system #51</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-51.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 4, 2017 at 13:24:16 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "January 4, 2017",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "50",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-50.html'>50</a>",
      drawDate: "2016-12-22",
      drawDateFull: "December 22, 2016",
      drawName: "No Program Specified",
      drawSize: "2,878",
      drawCRS: "475",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-50.html'>Invitations to apply for permanent residence under the Express Entry system #50</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-50.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 22, 2016 at 13:39:04 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "December 22, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "49",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-49.html'>49</a>",
      drawDate: "2016-12-16",
      drawDateFull: "December 16, 2016",
      drawName: "No Program Specified",
      drawSize: "1,936",
      drawCRS: "497",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-49.html'>Invitations to apply for permanent residence under the Express Entry system #49</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-49.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 16, 2016 at 16:42:31 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "December 16, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "48",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-48.html'>48</a>",
      drawDate: "2016-11-30",
      drawDateFull: "November 30, 2016",
      drawName: "Provincial Nominee Program",
      drawSize: "559",
      drawCRS: "786",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-48.html'>Invitations to apply for permanent residence under the Express Entry system #48</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-48.html'>See full text of Ministerial Instruction</a>",
      drawText2: "Provincial Nominee Program",
      drawDateTime: "November 30, 2016 at 14:58:44 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "November 30, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "47",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-47.html'>47</a>",
      drawDate: "2016-11-16",
      drawDateFull: "November 16, 2016",
      drawName: "No Program Specified",
      drawSize: "2,427",
      drawCRS: "470",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-47.html'>Invitations to apply for permanent residence under the Express Entry system #47</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-47.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 16, 2016 at 13:12:45 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "November 16, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "46",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-46.html'>46</a>",
      drawDate: "2016-11-02",
      drawDateFull: "November 2, 2016",
      drawName: "No Program Specified",
      drawSize: "2,080",
      drawCRS: "472",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-46.html'>Invitations to apply for permanent residence under the Express Entry system #46</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-46.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 2, 2016 at 12:34:57 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "November 2, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "45",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-45.html'>45</a>",
      drawDate: "2016-10-19",
      drawDateFull: "October 19, 2016",
      drawName: "No Program Specified",
      drawSize: "1,804",
      drawCRS: "475",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-45.html'>Invitations to apply for permanent residence under the Express Entry system #45</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-45.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 19, 2016 at 12:20:50 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "October 19, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "44",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-44.html'>44</a>",
      drawDate: "2016-10-12",
      drawDateFull: "October 12, 2016",
      drawName: "No Program Specified",
      drawSize: "1,518",
      drawCRS: "484",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-44.html'>Invitations to apply for permanent residence under the Express Entry system #44</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-44.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 12, 2016 at 12:18:33 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "October 12, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "43",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-43.html'>43</a>",
      drawDate: "2016-09-21",
      drawDateFull: "September 21, 2016",
      drawName: "No Program Specified",
      drawSize: "1,288",
      drawCRS: "483",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-43.html'>Invitations to apply for permanent residence under the Express Entry system #43</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-43.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 21, 2016 at 12:05:41 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "September 21, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "42",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-42.html'>42</a>",
      drawDate: "2016-09-07",
      drawDateFull: "September 7, 2016",
      drawName: "No Program Specified",
      drawSize: "1,000",
      drawCRS: "491",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-42.html'>Invitations to apply for permanent residence under the Express Entry system #42</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-42.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 7, 2016 at 12:09:27 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "September 7, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "41",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-41.html'>41</a>",
      drawDate: "2016-08-24",
      drawDateFull: "August 24, 2016",
      drawName: "No Program Specified",
      drawSize: "750",
      drawCRS: "538",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-41.html'>Invitations to apply for permanent residence under the Express Entry system #41</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-41.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 24, 2016 at 12:12:22 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "August 24, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "40",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-40.html'>40</a>",
      drawDate: "2016-08-10",
      drawDateFull: "August 10, 2016",
      drawName: "No Program Specified",
      drawSize: "754",
      drawCRS: "490",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-40.html'>Invitations to apply for permanent residence under the Express Entry system #40</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-40.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 10, 2016 at 11:17:01 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "August 10, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "39",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-39.html'>39</a>",
      drawDate: "2016-07-27",
      drawDateFull: "July 27, 2016",
      drawName: "No Program Specified",
      drawSize: "755",
      drawCRS: "488",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-39.html'>Invitations to apply for permanent residence under the Express Entry system #39</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-39.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 27, 2016 at 12:40:23 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "July 27, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "38",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-38.html'>38</a>",
      drawDate: "2016-07-13",
      drawDateFull: "July 13, 2016",
      drawName: "No Program Specified",
      drawSize: "747",
      drawCRS: "482",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-38.html'>Invitations to apply for permanent residence under the Express Entry system #38</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-38.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: " July 13, 2016 at 13:56:45 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "July 13, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "37",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-37.html'>37</a>",
      drawDate: "2016-06-29",
      drawDateFull: "June 29, 2016",
      drawName: "No Program Specified",
      drawSize: "773",
      drawCRS: "482",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-37.html'>Invitations to apply for permanent residence under the Express Entry system #37</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-37.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 29, 2016 at 12:10:42 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "June 29, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "36",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-36.html'>36</a>",
      drawDate: "2016-06-15",
      drawDateFull: "June 15, 2016",
      drawName: "No Program Specified",
      drawSize: "752",
      drawCRS: "488",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-36.html'>Invitations to apply for permanent residence under the Express Entry system #36</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-36.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 15, 2016 at 13:35:01 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "June 15, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "35",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-35.html'>35</a>",
      drawDate: "2016-06-01",
      drawDateFull: "June 1, 2016",
      drawName: "No Program Specified",
      drawSize: "762",
      drawCRS: "483",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-35.html'>Invitations to apply for permanent residence under the Express Entry system #35</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-35.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 1, 2016 at 18:59:32 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "June 1, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "34",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-34.html'>34</a>",
      drawDate: "2016-05-18",
      drawDateFull: "May 18, 2016",
      drawName: "No Program Specified",
      drawSize: "763",
      drawCRS: "484",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-34.html'>Invitations to apply for permanent residence under the Express Entry system #34</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-34.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 18, 2016 at 13:45:56 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "May 18, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "33",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-33.html'>33</a>",
      drawDate: "2016-05-06",
      drawDateFull: "May 6, 2016",
      drawName: "No Program Specified",
      drawSize: "799",
      drawCRS: "534",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-33.html'>Invitations to apply for permanent residence under the Express Entry system #33</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-33.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 6, 2016 at 12:47:39 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "May 6, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "32",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-32.html'>32</a>",
      drawDate: "2016-04-20",
      drawDateFull: "April 20, 2016",
      drawName: "No Program Specified",
      drawSize: "1,018",
      drawCRS: "468",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-32.html'>Invitations to apply for permanent residence under the Express Entry system #32</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-32.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 20, 2016 at 12:29:05 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "April 20, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "31",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-31.html'>31</a>",
      drawDate: "2016-04-06",
      drawDateFull: "April 6, 2016",
      drawName: "No Program Specified",
      drawSize: "954",
      drawCRS: "470",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-31.html'>Invitations to apply for permanent residence under the Express Entry system #31</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-31.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 6, 2016 at 12:32:17 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "April 6, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "30",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-30.html'>30</a>",
      drawDate: "2016-03-23",
      drawDateFull: "March 23, 2016",
      drawName: "No Program Specified",
      drawSize: "1,014",
      drawCRS: "470",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-30.html'>Invitations to apply for permanent residence under the Express Entry system #30</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-30.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 23, 2016 at 12:20:45 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "March 23, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "29",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-29.html'>29</a>",
      drawDate: "2016-03-09",
      drawDateFull: "March 9, 2016",
      drawName: "No Program Specified",
      drawSize: "1,013",
      drawCRS: "473",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-29.html'>Invitations to apply for permanent residence under the Express Entry system #29</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-29.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 9, 2016 at 13:11:07 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "March 9, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "28",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-28.html'>28</a>",
      drawDate: "2016-02-24",
      drawDateFull: "February 24, 2016",
      drawName: "No Program Specified",
      drawSize: "1,484",
      drawCRS: "453",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-28.html'>Invitations to apply for permanent residence under the Express Entry system #28</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-28.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 24, 2016 at 13:03:55 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "February 24, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "27",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-27.html'>27</a>",
      drawDate: "2016-02-10",
      drawDateFull: "February 10, 2016",
      drawName: "No Program Specified",
      drawSize: "1,505",
      drawCRS: "459",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-27.html'>Invitations to apply for permanent residence under the Express Entry system #27</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-27.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 10, 2016 at 13:07:36 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "February 10, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "26",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-26.html'>26</a>",
      drawDate: "2016-01-27",
      drawDateFull: "January 27, 2016",
      drawName: "No Program Specified",
      drawSize: "1,468",
      drawCRS: "457",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-26.html'>Invitations to apply for permanent residence under the Express Entry system #26</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-26.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 27, 2016 at 13:44:40 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "January 27, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "25",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-25.html'>25</a>",
      drawDate: "2016-01-13",
      drawDateFull: "January 13, 2016",
      drawName: "No Program Specified",
      drawSize: "1,518",
      drawCRS: "453",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-25.html'>Invitations to apply for permanent residence under the Express Entry system #25</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-25.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 13, 2016 at 12:59:27 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "January 13, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "24",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-24.html'>24</a>",
      drawDate: "2016-01-06",
      drawDateFull: "January 6, 2016",
      drawName: "No Program Specified",
      drawSize: "1,463",
      drawCRS: "461",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-24.html'>Invitations to apply for permanent residence under the Express Entry system #24</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-24.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 6, 2016 at 13:28:25 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "January 6, 2016",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "23",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-23.html'>23</a>",
      drawDate: "2015-12-18",
      drawDateFull: "December 18, 2015",
      drawName: "No Program Specified",
      drawSize: "1,503",
      drawCRS: "460",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-23.html'>Invitations to apply for permanent residence under the Express Entry system #23</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-23.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 18, 2015 at 15:56:10 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "December 18, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "22",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-22.html'>22</a>",
      drawDate: "2015-12-04",
      drawDateFull: "December 4, 2015",
      drawName: "No Program Specified",
      drawSize: "1,451",
      drawCRS: "461",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-22.html'>Invitations to apply for permanent residence under the Express Entry system #22</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-22.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "December 4, 2015 at 13:15:46 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "December 4, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "21",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-21.html'>21</a>",
      drawDate: "2015-11-27",
      drawDateFull: "November 27, 2015",
      drawName: "No Program Specified",
      drawSize: "1,559",
      drawCRS: "472",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-21.html'>Invitations to apply for permanent residence under the Express Entry system #21</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-21.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 27, 2015 at 15:58:32 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "November 27, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "20",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-20.html'>20</a>",
      drawDate: "2015-11-13",
      drawDateFull: "November 13, 2015",
      drawName: "No Program Specified",
      drawSize: "1,506",
      drawCRS: "484",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-20.html'>Invitations to apply for permanent residence under the Express Entry system #20</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-20.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "November 13, 2015 at 12:34:37 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "November 13, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "19",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-19.html'>19</a>",
      drawDate: "2015-10-23",
      drawDateFull: "October 23, 2015",
      drawName: "No Program Specified",
      drawSize: "1,502",
      drawCRS: "489",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-19.html'>Invitations to apply for permanent residence under the Express Entry system #19</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-19.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 23, 2015 at 12:34:37 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "October 23, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "18",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-18.html'>18</a>",
      drawDate: "2015-10-02",
      drawDateFull: "October 2, 2015",
      drawName: "No Program Specified",
      drawSize: "1,530",
      drawCRS: "450",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-18.html'>Invitations to apply for permanent residence under the Express Entry system #18</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-18.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "October 2, 2015 at 12:28:11 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "October 2, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "17",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-17.html'>17</a>",
      drawDate: "2015-09-18",
      drawDateFull: "September 18, 2015",
      drawName: "No Program Specified",
      drawSize: "1,545",
      drawCRS: "450",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-17.html'>Invitations to apply for permanent residence under the Express Entry system #17</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-17.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 18, 2015 at 14:51:28 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "September 18, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "16",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-16.html'>16</a>",
      drawDate: "2015-09-08",
      drawDateFull: "September 8, 2015",
      drawName: "No Program Specified",
      drawSize: "1,517",
      drawCRS: "459",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-16.html'>Invitations to apply for permanent residence under the Express Entry system #16</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-16.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "September 8, 2015 at 15:41:50 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "September 8, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "15",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-15.html'>15</a>",
      drawDate: "2015-08-21",
      drawDateFull: "August 21, 2015",
      drawName: "No Program Specified",
      drawSize: "1,523",
      drawCRS: "456",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-15.html'>Invitations to apply for permanent residence under the Express Entry system #15</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-15.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 21, 2015 at 14:38:31 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "August 21, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "14",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-14.html'>14</a>",
      drawDate: "2015-08-07",
      drawDateFull: "August 7, 2015",
      drawName: "No Program Specified",
      drawSize: "1,402",
      drawCRS: "471",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-14.html'>Invitations to apply for permanent residence under the Express Entry system #14</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-14.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "August 7, 2015 at 21:10:39 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "August 7, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "13",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-13.html'>13</a>",
      drawDate: "2015-07-17",
      drawDateFull: "July 17, 2015",
      drawName: "No Program Specified",
      drawSize: "1,581",
      drawCRS: "451",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-13.html'>Invitations to apply for permanent residence under the Express Entry system #13</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-13.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 18, 2015 at 00:04:00 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "July 17, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "12",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-12.html'>12</a>",
      drawDate: "2015-07-10",
      drawDateFull: "July 10, 2015",
      drawName: "No Program Specified",
      drawSize: "1,516",
      drawCRS: "463",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-12.html'>Invitations to apply for permanent residence under the Express Entry system #12</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-12.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "July 10, 2015 at 23:59:57 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "July 10, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "11",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-11.html'>11</a>",
      drawDate: "2015-06-26",
      drawDateFull: "June 26, 2015",
      drawName: "No Program Specified",
      drawSize: "1,575",
      drawCRS: "469",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-11.html'>Invitations to apply for permanent residence under the Express Entry system #11</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-11.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 27, 2015 at 00:02:00 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "June 26, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "10",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-10.html'>10</a>",
      drawDate: "2015-06-12",
      drawDateFull: "June 12, 2015",
      drawName: "No Program Specified",
      drawSize: "1,501",
      drawCRS: "482",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-10.html'>Invitations to apply for permanent residence under the Express Entry system #10</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-10.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "June 12, 2015 at 23:59:55 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "June 12, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "9",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-9.html'>9</a>",
      drawDate: "2015-05-22",
      drawDateFull: "May 22, 2015",
      drawName: "No Program Specified",
      drawSize: "1,361",
      drawCRS: "755",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-9.html'>Invitations to apply for permanent residence under the Express Entry system #9</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-9.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "May 23, 2015 at 00:00:00 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "May 22, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "8",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-8.html'>8</a>",
      drawDate: "2015-04-17",
      drawDateFull: "April 17, 2015",
      drawName: "No Program Specified",
      drawSize: "715",
      drawCRS: "453",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-8.html'>Invitations to apply for permanent residence under the Express Entry system #8</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-8.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 18, 2015 at 00:00:00 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "April 17, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "7",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-7.html'>7</a>",
      drawDate: "2015-04-10",
      drawDateFull: "April 10, 2015",
      drawName: "No Program Specified",
      drawSize: "925",
      drawCRS: "469",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-7.html'>Invitations to apply for permanent residence under the Express Entry system #7</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-7.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "April 11, 2015 at 00:00:01 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "April 10, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "6",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-6.html'>6</a>",
      drawDate: "2015-03-27",
      drawDateFull: "March 27, 2015",
      drawName: "No Program Specified",
      drawSize: "1,637",
      drawCRS: "453",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-6.html'>Invitations to apply for permanent residence under the Express Entry system #6</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-6.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 27, 2015 at 23:59:47",
      drawCutOff: "",
      drawDistributionAsOn: "March 27, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "5",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-5.html'>5</a>",
      drawDate: "2015-03-20",
      drawDateFull: "March 20, 2015",
      drawName: "No Program Specified",
      drawSize: "1,620",
      drawCRS: "481",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-5.html'>Invitations to apply for permanent residence under the Express Entry system #5</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-5.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "March 20, 2015 at 23:59:53 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "March 20, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "4",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-4.html'>4</a>",
      drawDate: "2015-02-27",
      drawDateFull: "February 27, 2015",
      drawName: "No Program Specified",
      drawSize: "1,187",
      drawCRS: "735",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-4.html'>Invitations to apply for permanent residence under the Express Entry system #4</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-4.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 27, 2015 at 23:59:51 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "February 27, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "3",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-3.html'>3</a>",
      drawDate: "2015-02-20",
      drawDateFull: "February 20, 2015",
      drawName: "Canadian Experience Class",
      drawSize: "849",
      drawCRS: "808",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-3.html'>Invitations to apply for permanent residence under the Express Entry system #3</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-3.html'>See full text of Ministerial Instruction</a>",
      drawText2: "Canadian Experience Class",
      drawDateTime: "February 20, 2015 at 11:59:47 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "February 20, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "2",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-2.html'>2</a>",
      drawDate: "2015-02-07",
      drawDateFull: "February 7, 2015",
      drawName: "No Program Specified",
      drawSize: "779",
      drawCRS: "818",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-2.html'>Invitations to apply for permanent residence under the Express Entry system #2</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-2.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "February 7, 2015 at 11:59:59 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "February 7, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
    {
      drawNumber: "1",
      drawNumberURL:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-1.html'>1</a>",
      drawDate: "2015-01-31",
      drawDateFull: "January 31, 2015",
      drawName: "No Program Specified",
      drawSize: "779",
      drawCRS: "886",
      mitext:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-1.html'>Invitations to apply for permanent residence under the Express Entry system #1</a>",
      DrawText1:
        "<a href='/en/immigration-refugees-citizenship/corporate/mandate/policies-operational-instructions-agreements/ministerial-instructions/express-entry-rounds/invitations-1.html'>See full text of Ministerial Instruction</a>",
      drawText2:
        "Federal Skilled Worker, Canadian Experience Class, Federal Skilled Trades and Provincial Nominee Program",
      drawDateTime: "January 31, 2015 at 11:59:48 UTC",
      drawCutOff: "",
      drawDistributionAsOn: "January 31, 2015",
      dd1: "0",
      dd2: "0",
      dd3: "0",
      dd4: "0",
      dd5: "0",
      dd6: "0",
      dd7: "0",
      dd8: "0",
      dd9: "0",
      dd10: "0",
      dd11: "0",
      dd12: "0",
      dd13: "0",
      dd14: "0",
      dd15: "0",
      dd16: "0",
      dd17: "0",
      dd18: "0",
    },
  ],
};
